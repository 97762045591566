import React, {FC, ReactElement} from "react";
import {Box} from "@mui/material";

type StemsPlayCustomTimeProps = {
  currentTime: string,
  totalTime: string
}
const wrapperStyles = {
  position: 'sticky',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 999,
  left: '0',
  right: '0',
  top: '32px',
  background: '#151515',
  borderBottom: '1px solid #494949',
}
const timeStylesLeft = {
  color: 'white',
  backgroundColor: '#494949',
  padding: '2px',
  fontSize: '12px',
  borderRadius: '2px',
  position: 'absolute',
  fontFamily: 'DM Sans, sans-serif',
  width: '40px',
  textAlign: 'center',
  left: '5px',
  top: '6px',
}
const timeStylesRight = {
  color: 'white',
  backgroundColor: '#494949',
  padding: '2px',
  fontSize: '12px',
  borderRadius: '2px',
  position: 'absolute',
  fontFamily: 'DM Sans, sans-serif',
  width: '40px',
  right: '5px',
  top: '6px',
  textAlign: 'center'
}
const StemsPlayCustomTime: FC<StemsPlayCustomTimeProps> = (props): ReactElement => {
  const {currentTime, totalTime} = props
  return (
    <Box sx={wrapperStyles}>
      <Box sx={timeStylesLeft}>
        {currentTime}
      </Box>
      <Box sx={timeStylesRight}>
        {totalTime}
      </Box>
    </Box>
  )
}

export default StemsPlayCustomTime;
