import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/router';
import authService from './services/authService';
import { useTokenStore } from './store/token.store';
import { useAxiosInterceptors } from './utils/axios';

function App() {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const setToken = useTokenStore(state => state.setToken);
  const setIsTokenLoading = useTokenStore(state => state.setLoading);
  const token = useTokenStore(state => state.token);
  const isTokenLoading = useTokenStore(state => state.isLoading);
  const setFinished = useTokenStore(state => state.setFinished);

  const getToken = async () => {
    setIsTokenLoading(true);

    return await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? ''
      }
    }).then(t => {
      setToken(t);
      setIsTokenLoading(false);
      setFinished(true);
    });
  };

  useEffect(() => {
    const storedDate = localStorage.getItem('expiryDate');

    if (storedDate) {
      const expiryDate = new Date(storedDate);
      const now = new Date();

      if (now > expiryDate) {
        localStorage.removeItem('guest_user_token');
        localStorage.removeItem('guest_user_token_expired');
      }
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        getToken();
      } else {
        if (!localStorage.getItem('guest_user_token')) {
          authService.createGuestUser().then(user => {
            localStorage.setItem('guest_user_token', user.guestToken);

            const now = new Date();
            const expiryDate = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
            localStorage.setItem('guest_user_token_expired', expiryDate.toISOString());

            setFinished(true);
          });
        } else {
          setFinished(true);
        }
      }
    }
  }, [isAuthenticated, isLoading]);

  useAxiosInterceptors(token);

  return (!isLoading && !isTokenLoading) || (!isAuthenticated && !!localStorage.getItem('guest_user_token')) ? (
    <RouterProvider router={router} />
  ) : null; // add suspense or loader for it
}

export default App;
