import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const NotFoundComponent = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleButtonClick = () => {
    if (!isAuthenticated) loginWithRedirect();
    navigate('/dashboard');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Typography variant="h4" align="center">
        404 - Not Found
      </Typography>
      <Button onClick={handleButtonClick} variant="contained" color="primary" style={{ marginTop: 20 }}>
        {isAuthenticated ? 'Back to Dashboard' : 'Back to sign-in page'}
      </Button>
    </div>
  );
};

export default NotFoundComponent;
