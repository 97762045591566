import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { AudioFile } from '../@types/songs';
import { usePlaylistStore } from '../store/players.store';

type WavesurferProps = {
  song: AudioFile | null;
  currentVersion: string | null;
};

export function Wavesurfer({ song, currentVersion }: WavesurferProps) {
  const songWaveForm = useRef<HTMLDivElement | null>(null);
  const initWavesurfer = usePlaylistStore(state => state.initWavesurfer);
  const { waveSurfer, songUrl } = usePlaylistStore(state => state.playListStates.find(state => state.versionId === currentVersion)) || {};
  const handleIsSongRendered = usePlaylistStore(state => state.handleIsSongRendered);

  useEffect(() => {
    if (song) {
      if (waveSurfer && songUrl === song.url) {
        waveSurfer?.setOptions({
          container: songWaveForm.current!
        });
        return;
      }

      const songWS = WaveSurfer.create({
        container: songWaveForm.current!,
        waveColor: '#A7A7A7',
        progressColor: '#008EF3',
        height: 56,
        cursorWidth: 1,
        barWidth: 3,
        barGap: 2,
        barRadius: 1,
        barHeight: 2,
        cursorColor: 'transparent'
      });

      songWS.load(song.url);

      songWS.on('ready', () => {
        handleIsSongRendered(true, currentVersion || '');
        initWavesurfer(songWS, song.url, currentVersion || '');
      });

      songWS.on('finish', () => {
        songWS.pause();
      });
    } else {
      if (currentVersion) {
        initWavesurfer(null, '', currentVersion);
      }
    }
  }, [song?.url]);

  return <Box ref={songWaveForm} sx={{ overflowY: 'hidden', height: '29px' }} />;
}
