import { Box, Button, Tooltip } from '@mui/material';
import React from 'react';
import { AudioFile } from '../../@types/songs';

export type SongShowButtonProps = {
  song: AudioFile | null;
  isSongShown: boolean;
  toggleSongShown: () => void;
};

export function SongShowButton({ isSongShown, toggleSongShown, song }: SongShowButtonProps) {
  return (
    <Box>
      <Tooltip title={isSongShown ? (song ? 'Hide song area' : 'Hide song upload box') : (song ? 'Show song area' : 'Show song upload box')}>
        <Button
          sx={{
            width: '70px',
            height: '28px',
            weight: '400',
            lineHeight: '18px',
            boxShadow: 'none',
            ...(!isSongShown && {
              opacity: 0.5,
              alignItems: 'end'
            })
          }}
          onClick={toggleSongShown}
          size="small"
          disableElevation={true}
          variant="contained"
          color="secondary"
        >
          Song
          {isSongShown ? (
            <img style={{ marginLeft: 7 }} src="/assets/opened-eye.svg" alt="closed eye" />
          ) : (
            <img style={{ marginLeft: 7 }} src="/assets/closed-eye.svg" alt="closed eye" />
          )}
        </Button>
      </Tooltip>
    </Box>
  );
}
