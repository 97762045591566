type TaskFunction = (worker?: Worker) => Promise<any>;

export class TaskQueue {
    private maxConcurrentTasks: number;
    private currentRunningTasks: number;
    private queue: (() => void)[];

    constructor(maxConcurrentTasks: number) {
        this.maxConcurrentTasks = maxConcurrentTasks;
        this.currentRunningTasks = 0;
        this.queue = [];
    }

    async runTask(task: TaskFunction): Promise<any> {
        if (this.currentRunningTasks >= this.maxConcurrentTasks) {
            // Wait until there's room to run the task
            await new Promise<void>((resolve) => this.queue.push(resolve));
        }
        this.currentRunningTasks++;
        // Run the task
        try {
            return await task();
        } finally {
            this.currentRunningTasks--;
            if (this.queue.length > 0) {
                // Start the next task in the queue
                const nextTaskStart = this.queue.shift();
                if (nextTaskStart) {
                    nextTaskStart();
                }
            }
        }
    }
}