import { AxiosInstance, default as defaultAxios } from 'axios';
import { useSnackbar } from 'notistack';
import { apiUrl } from '../configs';

const axios: AxiosInstance = defaultAxios.create({
  baseURL: apiUrl
});

export const useAxiosInterceptors = (token: string) => {
  const { enqueueSnackbar } = useSnackbar();

  if (token) {
    axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    });

    axios.interceptors.response.use(
      response => response,
      error => {
        // if (error.response.status !== 502) {
        //   enqueueSnackbar(error.message, { variant: 'error' });
        // }
      }
    );
  } else {
    axios.interceptors.request.use(config => {
      config.headers['guest-token'] = localStorage.getItem('guest_user_token');

      return config;
    });

    axios.interceptors.response.use(
      response => response,
      error => {
        // if (error.response.status !== 502) {
        //   enqueueSnackbar(error.message, { variant: 'error' });
        // }
      }
    );
  }

  return axios;
};

export default axios;
