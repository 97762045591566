import { InfoOutlined } from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, CircularProgress, Grid, Icon, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import SimpleBar from 'simplebar-react';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { formatDateMDY } from '../utils/date';
import { useAuth0 } from '@auth0/auth0-react';
const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  const message = 'Are you sure you want to leave? all ongoing uploads will be cancelled';
  event.returnValue = message;
  return message;
};
export function UploadingProgressBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { song, currentVersion, songParentId, renameSong, inviteUser } = useSongSelector();
  const [isOpen, setIsOpen] = useState(false);
  const { createSongInFolder } = useFoldersSelector();
  const { user } = useAuth0();

  const {
    uploadingSongs,
    lastQueue,
    handleProgressForStems,
    handleEstimatedTimeForStems,
    handleEstimatedTimeForSong,
    handleProgressForSong,
    uploadSong,
    uploadStem,
    removeUploadSong
  } = useUploadFilesSelector();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (uploadingSongs.length > 0) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadingSongs.length]);

  useEffect(() => {
    uploadingSongs.forEach(({ totalProgress, song: songToUpload, folderId, songId, isNewSong }) => {
      if (totalProgress === 100) {
        enqueueSnackbar(`Successfully uploaded`, { variant: 'success' });
        removeUploadSong(songId!);
      }
    });
  }, [uploadingSongs]);

  const toggleOpen = () => setIsOpen(prev => !prev);

  const handleUploadData = async () => {
    for (const { song: songToUpload, stems: stemsToUpload, songId, folderId, isUploadingSong, isNewSong } of uploadingSongs) {
      let promises = [];
      if (songToUpload && songToUpload.progress === 0 && !isUploadingSong) {
        promises.push(
          uploadSong(
            {
              folderId: folderId ?? '',
              songId: songId ?? '',
              versionId: songToUpload.versionId ?? '',
              fileId: song?.id ?? '',
              meta: {
                name: songToUpload?.file?.name ?? '',
                mimeType: songToUpload?.file.type ?? '',
                originalSize: songToUpload?.file.size ?? 1
              }
            },
            songToUpload?.file!,
            handleProgressForSong,
            songId,
            handleEstimatedTimeForSong
          ).then(async () => {
            await renameSong(songToUpload?.file?.name);
          })
        );
      }
      for (let index = 0; index < stemsToUpload.length; index++) {
        stemsToUpload[index].queueId === lastQueue &&
          promises.push(
            uploadStem(
              {
                folderId: folderId ?? '',
                fileId: stemsToUpload[index].stemId,
                songId: songId ?? '',
                versionId: stemsToUpload[index].versionId ?? '',
                meta: {
                  name: stemsToUpload[index]?.file.name ?? '',
                  mimeType: stemsToUpload[index]?.file.type ?? '',
                  originalSize: stemsToUpload[index]?.file.size ?? 1,
                  order: stemsToUpload[index]?.order
                }
              },
              stemsToUpload[index].file,
              (progress: number, songId: string) => handleProgressForStems(index, progress, songId),
              songId,
              (estimated: number, songId: string) => handleEstimatedTimeForStems(index, estimated, songId)
            )
          );
      }
      await Promise.all(promises);
      isNewSong &&
        promises.length > 0 &&
        createSongInFolder(folderId!, {
          id: songId ?? '',
          name:
            songToUpload?.file?.name ??
            stemsToUpload?.at(0)?.folderName ??
            stemsToUpload?.at(0)?.file?.name ??
            'DEMO_' + formatDateMDY(new Date()),
          folderId: folderId ?? ''
        }, user?.name || user?.email);
    }
  };

  useEffect(() => {
    handleUploadData();
  }, [lastQueue]);

  return (
    <Stack
      sx={{ width: isMobile ? '100%' : '30%' }}
      direction={'column'}
      spacing={2}
      position="absolute"
      bottom={isMobile ? 0 : 20}
      right={isMobile ? 0 : 24}
      zIndex={1000}
    >
      <Box
        sx={{
          width: '100%',
          height: isOpen ? 'max-content' : '70px',
          maxHeight: '400px',
          color: '#fff',
          transition: 'height 0.5s',
          background: '#1F1F1F',
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
          border: '1px solid #3C3C3C',
          borderBottom: 'none',
          overflow: 'hidden'
        }}
      >
        <Stack
          onClick={() => toggleOpen()}
          flexDirection={'row'}
          sx={{ borderBottom: isOpen ? '1px solid #3C3C3C' : 'none', maxHeight: '69px', cursor: 'pointer' }}
          position={'relative'}
        >
          <Box
            position={'absolute'}
            height={1}
            width={`${uploadingSongs.reduce((total, uploadState) => total + uploadState.totalProgress, 0) / uploadingSongs.length}%`}
            sx={{ background: 'linear-gradient(270deg, #008EF3 0%, #16E3F5 100%)' }}
          />
          <Box
            zIndex={1}
            display={'flex'}
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '69px',
              height: isOpen ? '68px' : '69px',
              borderTopLeftRadius: '8px'
            }}
          >
            <UploadIcon fontSize="medium" />
          </Box>
          <Stack width={`calc(100% - 69px)`}>
            <Stack height={1} p={1.5} zIndex={1} justifyContent={'center'}>
              <Stack direction={'row'} alignItems={'center'} columnGap={1.5}>
                <Typography>
                  Uploaded files{' '}
                  {uploadingSongs.reduce((total, uploadState) => {
                    const uploadedStems = uploadState.stems.reduce(
                      (stemTotal, stem) => (stem.progress === 100 ? stemTotal + 1 : stemTotal),
                      0
                    );
                    const uploadedSong = uploadState.song && uploadState.song.progress === 100 ? 1 : 0;
                    return total + uploadedStems + uploadedSong;
                  }, 0)}{' '}
                  of {uploadingSongs.reduce((total, uploadState) => total + uploadState.stems.length + (uploadState.song ? 1 : 0), 0)}
                </Typography>
                <Tooltip
                  title={
                    'Feel free to start using and sharing your stems right away! Just remember, do not close this page until upload is fully complete.'
                  }
                >
                  <InfoOutlined />
                </Tooltip>
              </Stack>
              <Typography fontSize={14} fontWeight={400}>
                {uploadingSongs.reduce((uploadedFiles, uploadState) => {
                  const uploadedStems = uploadState.stems.reduce((total, stem) => (stem.progress === 100 ? total + 1 : total), 0);
                  const uploadedSong = uploadState.song && uploadState.song.progress === 100 ? 1 : 0;
                  return uploadedFiles + uploadedStems + uploadedSong;
                }, 0) === 0 && 'Preparing upload'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Box p={2}>
          <SimpleBar style={{ width: '100%', maxHeight: '300px' }}>
            <Stack height={1}>
              <Stack flexDirection="column" spacing={0.5}>
                {uploadingSongs.map(({ totalProgress, song: songToUpload, stems: stemsToUpload, totalEstimatedTime, songId }) => (
                  <>
                    {!!songToUpload && (
                      <Stack
                        sx={{ background: 'rgba(44, 44, 44, 0.40)' }}
                        p={1.25}
                        borderRadius={0.5}
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Stack flexDirection="row" alignItems="center" columnGap={1}>
                          <Icon>
                            <img src={'/assets/song.svg'} height={24} width={24} alt="song" />
                          </Icon>
                          <Typography fontWeight={300} fontSize={14}>
                            {songToUpload?.file?.name}
                          </Typography>
                        </Stack>
                        <Stack flexDirection="row" alignItems="center" columnGap={1}>
                          <CircularProgress size={20} variant="determinate" color="info" value={songToUpload.progress} />
                        </Stack>
                      </Stack>
                    )}

                    {!!stemsToUpload.length && (
                      <Stack flexDirection="column">
                        <Grid container flexDirection="column" rowGap={0.5}>
                          {stemsToUpload.map((stem, i) => (
                            <Grid item key={stem.file.name}>
                              <Stack
                                sx={{ background: 'rgba(44, 44, 44, 0.40)' }}
                                p={1.25}
                                borderRadius={0.5}
                                flexDirection="row"
                                justifyContent="space-between"
                              >
                                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                                  <Icon>
                                    <img src={'/assets/wave.svg'} height={24} width={24} alt="song" />
                                  </Icon>
                                  <Typography fontWeight={300} fontSize={14}>
                                    {stem.file.name}
                                  </Typography>
                                </Stack>
                                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                                  <CircularProgress size={20} variant="determinate" color="info" value={stem.progress} />
                                </Stack>
                              </Stack>
                            </Grid>
                          ))}
                        </Grid>
                      </Stack>
                    )}
                  </>
                ))}
              </Stack>
            </Stack>
          </SimpleBar>
        </Box>
      </Box>
    </Stack>
  );
}
