import { Box, Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import songsService from '../services/songsService';

export function RequestAccessPage() {
  const [isRequestSent, setisRequestSent] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <Stack sx={{ background: '#000000', height: '100%', width: '100%' }} display="flex" alignItems="center">
      <Stack width="620px" sx={{ marginTop: '125px', background: '#151515' }} borderRadius={2} position="relative">
        <Box position={'absolute'} left={'50%'} top={'-45px'} sx={{ transform: 'translateX(-50%)' }}>
          <img src={'/assets/request-access.svg'} height={90} width={90} alt="request access icon" />
        </Box>
        <Stack sx={{ paddingTop: '64px', paddingBottom: isRequestSent ? '67px' : '39px' }} alignItems={'center'}>
          <Typography fontSize={20} lineHeight={'24px'} fontWeight={500} textAlign={'center'} mb={1.75}>
            {!isRequestSent ? 'You need access' : 'Request sent'}
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={'20px'}
            fontWeight={400}
            textAlign={'center'}
            mb={isRequestSent ? 0 : 10.25}
            sx={{ wordWrap: 'break-word' }}
          >
            {!isRequestSent
              ? 'Request access or switch to an account with access'
              : 'You’ll get an email letting you know if your request was approved'}
          </Typography>
          {!isRequestSent ? (
            <>
              <Typography sx={{ marginBottom: '27px' }} fontSize={16} lineHeight={'20px'} fontWeight={400} textAlign={'center'}>
                {isAuthenticated ? `You’re signed in as ${user?.email}` : "You're not signed in currently"}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                color="info"
                sx={{ padding: '8px 10px', lineHeight: '18px', fontSize: '16px' }}
                onClick={async () => {
                  const folderId = params.get('folderId');
                  const songId = params.get('songId');

                  await songsService.sendAccessRequestEmail(songId!, folderId!);

                  setisRequestSent(true);
                }}
              >
                Request access
              </Button>
            </>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}
