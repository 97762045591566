import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4b4b4b80',
      contrastText: '#FFFFFF'
    },
    info: {
      main: 'rgb(25, 118, 210)'
    },
    warning: {
      main: 'rgb(88, 60, 245)'
    },
    secondary: {
      main: 'rgba(75, 75, 75, 0.50)'
    },
    text: { primary: '#FFFFFF', secondary: '#ABABAB', disabled: '#FFFFFF' }
  },
  typography: {
    allVariants: {
      fontFamily: "DM Sans, sans-serif",
      color: '#FFFFFF'
    }
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#FFFFFF'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#FFFFFF'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          '&.Mui-focused': {
            color: '#FFFFFF'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#4b4b4b66'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          height: '28px',
          fontSize: '12px',
          padding: '6px 14px'
        },
        root: {
          textTransform: 'none',
          fontWeight: 400,
          '&.Mui-disabled': {
            color: '#ABABAB',
            backgroundColor: '#757575'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          ':focus-visible': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: 40,
          '&:not(:last-child)': {
            borderBottom: '1px solid #5e5e5e'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          backgroundColor: '#4b4b4b66',
          padding: 0
        },
        paper: {
          border: '1px solid white',
          marginTop: 12
        },
        option: {
          height: 40,
          '&:not(:last-child)': {
            borderBottom: '1px solid #5e5e5e'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212'
        }
      }
    }
  }
});

export default theme;
