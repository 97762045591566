import { Link, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function SubNavbar() {
  const location = useLocation();

  const getTabValue = () => {
    switch (location.pathname) {
      case '/account':
        return 0;
      case '/billings':
        return 1;
      case '/plans':
        return 2;
      default:
        return 0;
    }
  };

  const tabStyles = {
    color: '#FFFF',
    opacity: 0.5,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    '&.Mui-selected': {
      color: '#FFFF',
      opacity: 1
    }
  };

  return (
    <Box sx={{ padding: '10px 8px', display: 'flex', borderBottom: 1, borderColor: '#494949' }}>
      <Tabs
        value={getTabValue()}
        aria-label="navigation tabs"
        sx={{ '& .MuiTabs-indicator': { display: 'none' }, '& .MuiTabs-flexContainer': { gap: '16px' } }}
      >
        <Tab label="Plans" component={Link} to="/plans" sx={tabStyles} />
      </Tabs>
    </Box>
  );
}
