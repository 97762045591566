import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  Radio,
  Stack,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { SongVersion } from '../../@types/songs';
import { useSongSelector } from '../../store/selectors/song.selector';
import { formatDateMDTime } from '../../utils/date';
import { DialogCloseButton } from '../DialogCloseButton';

type VersionsDialogProps = {
  onClose: () => void;
  open: boolean;
  songName: string;
  uploadNewVersion: () => void;
  deleteVersion: (version: SongVersion) => void;
  changeVersion: (versionId: string) => void;
  canEdit: boolean;
};

export function VersionsDialog({ onClose, open, songName, uploadNewVersion, deleteVersion, changeVersion, canEdit }: VersionsDialogProps) {
  const { songVersions, currentVersion } = useSongSelector();

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { background: 'rgba(21, 21, 21, 1)', width: '100%', margin: 2 } }}
    >
      <DialogTitle
        sx={{
          width: '90%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500'
        }}
      >
        {songName}
      </DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent dividers sx={{ p: 2 }}>
        <Stack rowGap={1.5}>
          {songVersions.map(version => (
            <Grid
              key={version.id}
              container
              alignItems={'center'}
              px={2}
              py={1.5}
              sx={{ background: version.id === currentVersion ? '#8B8B8B4D' : 'unset' }}
              borderRadius={1}
              onClick={() => {
                changeVersion(version.id);
                onClose();
              }}
            >
              <Grid item xs>
                <Stack direction="row">
                  <Radio
                    sx={{
                      '&.MuiRadio-root': {
                        p: '0 !important'
                      },
                      mr: 2
                    }}
                    checked={version.id === currentVersion}
                  />
                  <Typography
                    variant="body1"
                    fontWeight={400}
                    fontSize={16}
                    sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    Version {version.versionNumber}
                    {version.createdAt && (
                      <Typography fontSize={12} color="text.secondary">
                        {`${formatDateMDTime(version.createdAt)}`}
                      </Typography>
                    )}
                  </Typography>
                </Stack>
              </Grid>
              {canEdit ? (
                <Grid item xs={'auto'}>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      deleteVersion(version);
                    }}
                    sx={{
                      minWidth: 'unset',
                      width: '32px',
                      height: '32px',
                      border: 'unset',
                      boxShadow: 'none'
                    }}
                  >
                    <Icon sx={{ width: 'unset', height: 'unset' }}>
                      <img src={'/assets/delete.svg'} height={20} width={20} alt="song" />
                    </Icon>
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          ))}
        </Stack>
      </DialogContent>
      {canEdit ? (
        <DialogActions sx={{ justifyContent: 'start' }}>
          <Button onClick={uploadNewVersion} color="secondary" variant="contained">
            Upload new
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
