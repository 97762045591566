import AddIcon from '@mui/icons-material/Add';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ExploreIcon from '@mui/icons-material/Explore';
import InfoIcon from '@mui/icons-material/Info';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Box, Button, DialogProps, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import CreateFolderDialog from '../Dialogs/CreateFolderDialog';
import CreateSongDialog from '../Dialogs/CreateSongDialog';

export default function CreateDropDownMenu() {
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
  const [openCreateSongDialog, setOpenCreateSongDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile) {
      event.stopPropagation();
    }

    setMenuEl(event.currentTarget);
  };

  const handleCloseMenu = (event?: any) => {
    if (isMobile && event) {
      event.stopPropagation();
    }

    setMenuEl(null);
  };

  const handleOpenCreateFolderDialog = (event: React.MouseEvent<HTMLLIElement>) => {
    if (isMobile) {
      event.stopPropagation();
    }

    handleCloseMenu();
    setOpenCreateFolderDialog(true);
  };

  const handleClose: DialogProps['onClose'] = (_: SyntheticEvent, reason: string) => {
    if (reason && reason === 'backdropClick') return;

    if (isMobile) {
      _.stopPropagation();
    }

    setOpenCreateSongDialog(false);
  };

  const handleOpenCreateSongDialog = (event: React.MouseEvent<HTMLLIElement>) => {
    if (isMobile) {
      event.stopPropagation();
    }

    handleCloseMenu();
    setOpenCreateSongDialog(true);
  };

  return (   
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {openCreateFolderDialog && (
        <CreateFolderDialog
          open={openCreateFolderDialog}
          onClose={(event, reason) => {
            if (isMobile) {
              event.stopPropagation();
            }

            setOpenCreateFolderDialog(false);
          }}
        />
      )}
      {openCreateSongDialog && <CreateSongDialog open={openCreateSongDialog} onClose={handleClose} />}
      <Box display="flex" gap={1} alignItems="center">
        <FolderOutlinedIcon opacity="50%" />
        <Typography variant="h6" fontSize={16}>
          Files & folders
        </Typography>
      </Box>
      <Button
        id="basic-button"
        aria-controls={Boolean(menuEl) ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(menuEl) ? 'true' : undefined}
        onClick={handleOpenMenu}
        variant="contained"
        size="small"
        sx={{ height: '28px', width: '28px', minWidth: 'unset' }}
      >
        <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ fontSize: 14, margin: 0 }}
        slotProps={{
          paper: {
            sx: {
              mt: 0
            }
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <MenuItem onClick={handleOpenCreateSongDialog}>
          <MusicNoteIcon fontSize="small" sx={{ mr: 1, fontSize: '14px' }} />
          Upload song
        </MenuItem> */}
        <MenuItem onClick={handleOpenCreateFolderDialog}>
          <FolderOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
          Create folder
        </MenuItem>
      </Menu>
    </Box>
  );
}
