import { create } from 'zustand';
import { UserInfo, UserPlan } from '../@types/auth';
import authService from '../services/authService';
import { SharedUserRoles } from '../@types/sharing';
import paymentService from '../services/paymentService';
import sharingService from '../services/sharingService';

type UserState = {
  user: UserInfo | null;
  userPlan: UserPlan | null;
  usedStorage: number;
  maxSizeStorage: number;
  userError: Error | null | string;
};

type UserActions = {
  getUser: () => Promise<void>;
  getUserPlan: () => Promise<void>;
  getUsedStorage: () => Promise<void>;
};

export const useUserStore = create<UserState & UserActions>(set => ({
  user: null,
  userPlan: null,
  userError: null,
  usedStorage: 0,
  maxSizeStorage: 0,
  getUserPlan: async () => {
    try {
      const userPlan = await paymentService.getUserPlan();

      let maxSizeStorage = 0;

      switch (userPlan?.plan) {
        case 'Premium':
          maxSizeStorage = 2000;
          break;
        case 'Creator':
          maxSizeStorage = 10000;
          break;
        default:
          maxSizeStorage = 2;
      }

      set({ userPlan, maxSizeStorage });
    } catch (err: any) {
      set({ userPlan: undefined, userError: err.message });
    }
  },
  getUsedStorage: async () => {
    try {
      const usedStorage = await authService.getUsedStorage();

      set({ usedStorage });
    } catch (err: any) {
      set({ usedStorage: 0, userError: err.message });
    }
  },
  getUser: async () => {
    try {
      const user = await authService.getUserInfo();

      set({ user, userError: null });
    } catch (e: any) {
      set({ user: null, userError: e.message });
    }
  }
}));

type UserSongAccessState = {
  songAccessType: SharedUserRoles | null;
};

type UserSongAccessActions = {
  getSongAccessType: (songId: string) => Promise<SharedUserRoles | null>;
};

export const useUserSongAccessStore = create<UserSongAccessState & UserSongAccessActions>(set => ({
  songAccessType: null,
  getSongAccessType: async songId => {
    try {
      const songAccessType = await sharingService.getSongAccessType(songId);

      set({ songAccessType });
      return songAccessType;
    } catch (e: any) {
      set({ songAccessType: null });
      return null;
    }
  }
}));
