import React from 'react';
import SubNavbar from '../components/SubNavbar';
import SimpleBar from 'simplebar-react';
import { ProfileTable } from '../components/ProfileTable';
import Typography from '@mui/material/Typography';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Button from '@mui/material/Button'; // Add this import statement
import TextField from '@mui/material/TextField'; // Add this import statement

export default function Account() {
  const data: Array<{ name: string; value: React.ReactNode; icon?: React.ReactNode }> = [
    { name: 'Profile picture', value: <TextField size="small" defaultValue="" />, icon: <AddAPhotoOutlinedIcon sx={{ color: '#717171' }} /> },
    { name: 'Full name', value: <TextField size="small" defaultValue="" />, icon: <AccountCircleOutlinedIcon sx={{ color: '#717171' }} /> },
    { name: 'Username', value: <TextField size="small" defaultValue="" />, icon: <AdminPanelSettingsOutlinedIcon sx={{ color: '#717171' }} /> },
    { name: 'Email', value: <TextField size="small" defaultValue="" />, icon: <MarkEmailUnreadOutlinedIcon sx={{ color: '#717171' }} /> },
    { name: 'Phone Number', value: <TextField size="small" defaultValue="" />, icon: <LocalPhoneOutlinedIcon sx={{ color: '#717171' }} /> }
  ];

  const sec: Array<{ name: string; value: React.ReactNode; icon?: React.ReactNode }> = [
    {
      name: 'Change password',
      value: <Button sx={{ color: 'white', textDecoration: 'underline' }}> Change Password</Button>,
      icon: <AdminPanelSettingsOutlinedIcon sx={{ color: '#717171' }} />
    },
    {
      name: 'Delete user',
      value: <Button sx={{ color: 'red', textDecoration: 'underline' }}>Delete User</Button>,
      icon: <DeleteOutlineOutlinedIcon sx={{ color: '#717171' }} />
    },
    {
      name: 'Delete user data',
      value: <Button sx={{ color: 'red', textDecoration: 'underline' }}>Delete User Data</Button>,
      icon: <DeleteOutlineOutlinedIcon sx={{ color: '#717171' }} />
    }
  ];

  return (
    <SimpleBar style={{ maxHeight: `calc(100vh - 70px)` }}>
      <SubNavbar />
      <div style={{ width: '70%', margin: '0 auto', marginTop: '30px' }}>
        <Typography variant="h5">Profile</Typography>
        <ProfileTable data={data} />
        <Typography variant="h5">Security & privacy</Typography>
        <ProfileTable data={sec} />
      </div>
      <hr style={{ width: '100%', margin: '0 auto', borderBottom: 1, borderColor: 'divider' }} />
      <div
        style={{
          width: '50%',
          margin: '0 auto',
          marginTop: '30px',
          marginBottom: '70px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <div style={{ flex: 1 }}></div>
        <Button
          variant="contained"
          sx={{
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkgrey'
            },
            marginRight: '12px' // Add 12px spacing
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#008EF3',
            color: 'white',
            '&:hover': {
              backgroundColor: '#008EF3'
            },
            marginLeft: '12px' // Add 12px spacing
          }}
        >
          Save changes
        </Button>
      </div>
    </SimpleBar>
  );
}
