import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import { UploadArea } from '../components/UploadArea';

export function WidgetTest() {
  const iframe = useRef<HTMLIFrameElement>(null);
  const [isDraggingStems, setIsDraggingStems] = useState(false);
  const uploadStems = (stems: FileList) => {
    iframe?.current?.contentWindow?.postMessage({
      stems,
      type: 'set-stem-player-data',
      colors: { background: 'green', waveOutlineColor: 'yellow', fadeColor: 'green' }
    });
  };
  console.log('iframe location is', window.location.origin + '/widget');
  return (
    <>
      <Box component={'div'} sx={{ position: 'relative', height: '300px' }}>
        <Box
          component="label"
          onDrop={(e: React.DragEvent<HTMLDivElement>) => {
            if (!isDraggingStems) return;
            e.preventDefault();
            const files = e.dataTransfer.files;
            setIsDraggingStems(false);
            uploadStems(files);
          }}
          onDragEnter={() => {
            setIsDraggingStems(true);
          }}
          onDragLeave={() => {
            setIsDraggingStems(false);
          }}
          onDragEnd={() => {
            setIsDraggingStems(false);
          }}
          onDragOver={e => {
            e.preventDefault();
          }}
          sx={{ position: 'absolute', width: '100%', height: '100%', cursor: 'copy', zIndex: 20 }}
        >
          <UploadArea onUpload={uploadStems} multiple={true} disabled={false} />
        </Box>
      </Box>
      <iframe ref={iframe} src={window.location.origin + '/widget'} style={{ width: '100%', height: '600px' }}></iframe>
    </>
  );
}
