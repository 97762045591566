import { Button, Grid, Typography } from '@mui/material';
import paymentService from '../../services/paymentService';

type CancelSubscriptionProps = {
  expiresAt: string;
  onClose: () => void;
};

export default function CancelledSubscriptionInfo({ expiresAt, onClose }: CancelSubscriptionProps) {
  const formatDate = () => {
    const date = new Date(expiresAt);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  };

  return (
    <Grid sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)' }}>
      <Grid item sx={{ gridColumn: '3 / 12' }}>
        <Typography fontSize={28} sx={{ lineHeight: '35px', paddingBottom: '20px' }}>
          You subscription was canceled
        </Typography>
      </Grid>

      <Grid item sx={{ gridColumn: '3 / 12' }}>
        <Typography fontSize={18} sx={{ lineHeight: '40px' }}>
          You’ll loose access to your files on {formatDate()} Be sure to download all files before then to ensure you don’t loose anything!{' '}
        </Typography>
      </Grid>

      <Grid item sx={{ gridColumn: '3 / 12' }}>
        <Button
          variant="contained"
          sx={{
            padding: '5px 8px',
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '-0.4%',
            backgroundColor: '#008EF3'
          }}
          onClick={onClose}
        >
          Go back to account page
        </Button>
      </Grid>
    </Grid>
  );
}
