import { create } from 'zustand';

type SidebarState = {
  isSidebarOpened: boolean;
};

type SidebarActions = {
  toggleSidebar: () => void;
};

export const useSidebarStore = create<SidebarState & SidebarActions>((set, get) => ({
  isSidebarOpened: false,
  toggleSidebar: () => {
    set({ isSidebarOpened: !get().isSidebarOpened });
  }
}));
