import { TextField } from '@mui/material';
import { ChangeEvent, useRef } from 'react';

type UploadAreaProps = {
  multiple: boolean;
  onUpload: (files: FileList) => void;
  disabled?: boolean;
};

export function UploadArea({ multiple, onUpload, disabled }: UploadAreaProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <TextField
      type="file"
      disabled={disabled}
      inputProps={{
        accept:
          'audio/*, audio/aac, audio/flac, audio/midi, audio/x-midi, audio/mpeg, audio/wav, audio/mp3, audio/ogg, audio/opus, audio/wav, audio/webm, audio/3gpp, audio/3gpp2, audio/mpeg, audio/mp4, audio/x-aiff, application/zip',
        multiple: multiple
      }}
      inputRef={fileInputRef}
      sx={{
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files) {
          onUpload(files);
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
          fileInputRef.current.files = null;
        }
      }}
    />
  );
}
