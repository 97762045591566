import axios from '../utils/axios';
import { SearchContent } from '../@types/search';

const getSearchContent = async (): Promise<SearchContent[]> => {
  const response = await axios.get<SearchContent[]>('search');
  return response.data;
};

const searchService = { getSearchContent };

export default searchService;
