import Grid from '@mui/material/Grid';
import SimpleBar from 'simplebar-react';
import type { SearchContent } from '../@types/search';
import SearchContentItem from './SearchContentItem';

export default function SearchContentList({ content, searchKey }: { content: SearchContent[]; searchKey: string }) {
  const filteredContent = content.filter(c => c?.name?.toLowerCase()?.includes(searchKey.toLowerCase() ?? false));

  return (
    <SimpleBar style={{ maxHeight: 280 }}>
      <Grid container direction="column" spacing={0}>
        {filteredContent.map(c => {
          return (
            <Grid item maxWidth={'100% !important'} justifyContent={'space-evenly'} marginTop={'0.5em'}>
              <SearchContentItem content={c} />
            </Grid>
          );
        })}
      </Grid>
    </SimpleBar>
  );
}
