import { Grid, Icon, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { NotificationType } from '../@types/notifications';
import { formatDateMDTime } from '../utils/date';

const NotificationIcon: Record<NotificationType, string> = {
  [NotificationType.ListenSong]: 'listenSong.svg',
  [NotificationType.DownloadSong]: 'downloadSong.svg',
  [NotificationType.UploadSong]: 'uploadSong.svg',
  [NotificationType.UploadNewVersion]: 'uploadNewVersion.svg'
};

type NotificationItemProps = {
  notificationType: NotificationType;
  songName: string;
  folderName?: string;
  senderName: string;
  updatedAt: Date;
};

export function NotificationItem({ notificationType, songName, senderName, folderName, updatedAt }: NotificationItemProps) {
  const getNotificationDescription = () => {
    switch (notificationType) {
      case NotificationType.ListenSong:
        return `${senderName} listened to "${songName ?? 'Deleted song'}"`;
      case NotificationType.DownloadSong:
        return `${senderName} downloaded "${songName ?? 'Deleted song'}"`;
      case NotificationType.UploadNewVersion:
        return `${senderName} uploaded new version to "${songName ?? 'Deleted song'}"`;
      case NotificationType.UploadSong:
        return `${senderName} uploaded ${songName ?? 'Deleted song'}${folderName === 'default_folder_for_uploads' ? '' : `to the folder "${folderName ?? 'Deleted folder'}"`}`;
      default:
        return 'Unknown notification';
    }
  };

  return (
    <Grid container columnGap={1} flexWrap={'nowrap'} maxWidth={'550px'}>
      <Grid item xs={'auto'}>
        <Icon sx={{ height: 32, width: 32 }}>
          <img src={`/assets/${NotificationIcon[notificationType]}`} height={32} width={32} alt="song" />
        </Icon>
      </Grid>
      <Grid item xs={11}>
        <Stack>
          <Tooltip title={getNotificationDescription()}>
            <Typography
              variant="body1"
              fontWeight={400}
              fontSize={12}
              sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {getNotificationDescription()}
              {updatedAt && (
                <Typography fontSize={12} color="text.secondary">
                  {`${formatDateMDTime(updatedAt.toString())}`}
                </Typography>
              )}
            </Typography>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );
}
