import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { AccessType, SharedUserInfo, SharedUserRoles } from '../@types/sharing';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useUserStore } from '../store/user.store';
import axios from '../utils/axios';
import { errorMessage } from '../utils/errorMessage';
import { ValidationPatterns } from '../utils/validationPatterns';
import { DialogCloseButton } from './DialogCloseButton';
import DoneIcon from '@mui/icons-material/Done';
import sharingService from '../services/sharingService';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';

type SharingDialogProps = {
  folderId?: string;
  sharedFolderUsers?: SharedUserInfo[];
  onClose: () => void;
  open: boolean;
  isOwner: boolean;
  folderName: string;
  ownerEmail?: string;
};

export default function SharingDialog({ sharedFolderUsers, folderId, onClose, open, isOwner, folderName, ownerEmail }: SharingDialogProps) {
  const getUser = useUserStore(state => state.getUser);
  const { shareFolderByInvite, deleteFolderSharedUser } = useFoldersSelector();
  const [showPopup, setShowPopup] = useState(false);
  const [option, setOption] = useState<string>('anyone');
  const [accessOption, setAccessOption] = useState('CO_OWNER');
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const userInfo = useUserStore(state => state.user);

  let timer: NodeJS.Timeout;

  const handleChange = async (event: SelectChangeEvent) => {
    await axios.post(`/folders/share-status/${folderId}`, {
      isPublic: event.target.value === 'anyone',
      accessType: event.target.value === 'anyone' ? 'CO_OWNER' : null,
      isOrganisation: event.target.value === 'organization'
    });

    setOption(event.target.value as string);
    setAccessOption(event.target.value === 'anyone' ? 'CO_OWNER' : '');
  };

  const handleAccessChange = async (event: SelectChangeEvent) => {
    await axios.post(`/folders/share-status/${folderId}`, {
      isPublic: option === 'anyone',
      accessType: event.target.value
    });

    setAccessOption(event.target.value as string);
  };

  useEffect(() => {
    getUser();

    axios.get(`/folders/get-share-status/${folderId}`).then(res => {
      if (res.data.isOrganisation) {
        setOption('organization');
        setAccessOption(res.data.accessType ?? '');
      } else {
        setOption(res.data.isPublic ? 'anyone' : 'invited');
        setAccessOption(res.data.accessType ?? '');
      }
    });
  }, []);

  const handleSetAccessType = async (sharedUserEmail: string, accessType: string) => {
    await shareFolderByInvite({
      email: sharedUserEmail,
      folderId: folderId ?? '',
      type: accessType,
      folderName
    });

    showSuccessMessage();

    formik.resetForm();
  };

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').matches(ValidationPatterns.email, 'Please enter a valid email'),
    accessType: Yup.string().required().oneOf(Object.values(SharedUserRoles), 'Invalid access type')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      accessType: SharedUserRoles.Downloader
    },
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      handleSetAccessType(values.email, values.accessType);

      formikHelpers.resetForm();
    }
  });

  const { touched, errors, handleSubmit, getFieldProps } = formik;

  const handleCopySharingLink = async () => {
    // const accessType = accessOption === 'view' ? SharedUserRoles.Viewer : SharedUserRoles.Downloader;
    const isFolderSharing = `isfs=${true}`;

    const urlPath = option === 'anyone' ? `/dashboard/public/${folderId}?${isFolderSharing}&open=true` : `/dashboard/${folderId}?open=true`;

    const urlForSharing = document.location.origin + urlPath;

    await navigator.clipboard.writeText(urlForSharing);
    setShowPopup(true);

    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  const showSuccessMessage = () => {
    setShowSuccessMsg(true);

    timer = setTimeout(() => {
      setShowSuccessMsg(false);
      clearTimeout(timer);
    }, 5000);
  };

  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    sharingService.getSharedUsersAutofill().then(data => setEmails(data.emails));
  }, []);

  const excludeEmails: string[] = sharedFolderUsers?.map(sharedUser => sharedUser.email) ?? [];

  const filteredEmails = emails.filter(email => !excludeEmails.includes(email));

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { background: 'rgba(21, 21, 21, 1)', width: '100%', margin: 2 } }}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <DialogCloseButton onClose={onClose} />
          <DialogTitle>Sharing {folderId && 'folder'}</DialogTitle>
          <DialogContent dividers>
            <Grid container alignItems="center" columnSpacing={1} mb={3}>
              <Grid item xs={10} display={'flex'}>
                <Stack
                  flexDirection="row"
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    backgroundColor: 'rgba(75, 75, 75, 0.4)',
                    paddingLeft: '12px',
                    height: '33px',
                    border: '1px solid #ABABAB',
                    borderRadius: '4px',
                    marginBottom: '6px'
                  }}
                >
                  <Autocomplete
                    fullWidth
                    options={filteredEmails}
                    clearOnBlur={false}
                    inputValue={formik.values.email}
                    value={formik.values.email}
                    onChange={(event, value, reason) => {
                      formik.setFieldValue('email', reason === 'clear' ? '' : value);
                    }}
                    renderInput={params => (
                      <div style={{ position: 'relative' }}>
                        <TextField
                          margin="dense"
                          {...params}
                          placeholder="Email"
                          size="small"
                          autoComplete="off"
                          variant="standard"
                          fullWidth
                          {...getFieldProps('email')}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            sx: {
                              '& .MuiAutocomplete-endAdornment': {
                                display: 'none'
                              }
                            }
                          }}
                          sx={{
                            '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
                              display: 'none'
                            }
                          }}
                        />
                        {touched.email && errors.email && (
                          <FormHelperText
                            error
                            sx={{
                              position: 'absolute',
                              top: '100%',
                              left: 0
                            }}
                          >
                            {errors.email}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  />
                  <Select
                    {...getFieldProps('accessType')}
                    IconComponent={ArrowDown}
                    disabled={!isOwner}
                    sx={{
                      color: '#ABABAB',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: 0
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0
                      }
                    }}
                  >
                    <MenuItem value={SharedUserRoles.Viewer}>{AccessType.CanView}</MenuItem>
                    <MenuItem value={SharedUserRoles.Downloader}>{AccessType.CanDownload}</MenuItem>
                    <MenuItem value={SharedUserRoles.Editor}>{AccessType.CanEdit}</MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Button
                  sx={{ height: '33px', marginBottom: '6px', padding: '8px', width: '52px' }}
                  type="submit"
                  color="info"
                  variant="contained"
                >
                  Invite
                </Button>
              </Grid>
            </Grid>
            <Typography mb={2}>People with access</Typography>
            <Grid container flexDirection="column" rowGap={1}>
              <Grid item sx={{ background: 'rgba(139, 139, 139, 0.30)' }} px={1.5} py={1.25} borderRadius={0.5}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Stack flexDirection="row" alignItems="center">
                    <PersonIcon />
                    <Typography ml={1}>{ownerEmail}</Typography>
                  </Stack>
                  <Typography color="#999999">Owner</Typography>
                </Stack>
              </Grid>
              {sharedFolderUsers?.map(sharedUser => (
                <Grid item key={sharedUser.email} sx={{ background: 'rgba(139, 139, 139, 0.30)' }} px={1.5} py={1.25} borderRadius={0.5}>
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Stack flexDirection="row" alignItems="center">
                      <PersonIcon />
                      <Typography ml={1}>{sharedUser.email}</Typography>
                    </Stack>
                    <Select
                      MenuProps={{ PaperProps: { sx: { width: '175px' } } }}
                      size="small"
                      defaultValue={sharedUser.accessType}
                      disabled={!isOwner}
                      renderValue={value => {
                        if (SharedUserRoles.Viewer === value) {
                          return AccessType.CanView;
                        }
                        if (SharedUserRoles.Editor === value) {
                          return AccessType.CanEdit;
                        }
                        if (SharedUserRoles.Downloader === value) {
                          return AccessType.CanDownload;
                        }
                      }}
                      onChange={async e => {
                        if (e.target.value === 'delete') {
                          await deleteFolderSharedUser(sharedUser.email, folderId ?? '');
                        } else {
                          //change for folder
                          await handleSetAccessType(sharedUser.email, e.target.value);
                        }
                      }}
                      SelectDisplayProps={{ style: { padding: 0, paddingRight: 32 } }}
                      sx={{
                        p: 0,
                        color: '#ABABAB',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                          border: 0
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 0
                        }
                      }}
                    >
                      <MenuItem value={SharedUserRoles.Viewer}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                          <Typography>{AccessType.CanView}</Typography>
                          {sharedUser.accessType === SharedUserRoles.Viewer && <DoneIcon fontSize={'small'} />}
                        </Stack>
                      </MenuItem>
                      <MenuItem value={SharedUserRoles.Downloader}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                          <Typography>{AccessType.CanDownload}</Typography>
                          {sharedUser.accessType === SharedUserRoles.Downloader && <DoneIcon fontSize={'small'} />}
                        </Stack>
                      </MenuItem>
                      <MenuItem value={SharedUserRoles.Editor}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                          <Typography>{AccessType.CanEdit}</Typography>
                          {sharedUser.accessType === SharedUserRoles.Editor && <DoneIcon fontSize={'small'} />}
                        </Stack>
                      </MenuItem>
                      <MenuItem value="delete" sx={{ color: '#F53C3C' }}>
                        Remove
                      </MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <Typography sx={{ padding: '16px 24px 12px' }}>General access</Typography>
          <DialogContent
            dividers
            sx={{
              '&.MuiDialogContent-dividers': {
                borderTop: 'none !important',
                display: 'flex',
                justifyContent: 'space-between'
              }
            }}
          >
            <Select
              onChange={handleChange}
              value={option}
              IconComponent={ArrowDown}
              sx={{
                color: '#ABABAB',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0
                },
                '& .MuiSelect-select': {
                  py: '0 !important'
                }
              }}
            >
              <MenuItem value={'invited'}>Only invited can access</MenuItem>
              {!!userInfo?.organizationId && <MenuItem value={'organization'}>{userInfo?.organizationName}</MenuItem>}
              <MenuItem value={'anyone'}>Anyone with the link</MenuItem>
            </Select>
            {option === 'anyone' && (
              <Select
                value={accessOption}
                onChange={handleAccessChange}
                IconComponent={ArrowDown}
                sx={{
                  color: '#ABABAB',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0
                  },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0
                  },
                  '& .MuiSelect-select': {
                    py: '0 !important'
                  }
                }}
              >
                <MenuItem value={'VIEWER'}>Can view</MenuItem>
                <MenuItem value={'CO_OWNER'}>Can download</MenuItem>
              </Select>
            )}
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'flex-start', paddingX: '24px', paddingY: '16px' }}>
            <Button
              disabled={option === 'invited'}
              onClick={handleCopySharingLink}
              sx={{
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '18px',
                letterSpacing: '-0.4%',
                paddingLeft: '6px',
                paddingRight: '8px',
                paddingY: '5px'
              }}
              size="small"
              color="secondary"
              variant="contained"
            >
              <LinkIcon sx={{ marginRight: '4px', width: '16px', height: '16px' }} />
              {showPopup ? 'Link copied!' : 'Copy link'}
            </Button>
            {showSuccessMsg && (
              <Stack direction={'row'} mt={1} alignItems={'center'}>
                <Typography fontSize={14} fontWeight={400} mr={0.5}>
                  Sent
                </Typography>
                <Box position={'relative'} height={'14px'} width={'14px'}>
                  <img src={'/assets/success.svg'} height={'14px'} width={'14px'} alt="song" />
                </Box>
              </Stack>
            )}
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
