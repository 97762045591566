import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

type DialogCloseButtonProps = {
  isDownloading?: boolean;
  onClose: () => void;
};

export function DialogCloseButton({ isDownloading, onClose }: DialogCloseButtonProps) {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        display: isDownloading ? 'none' : 'block'
      }}
    >
      <CloseIcon />
    </IconButton>
  );
}
