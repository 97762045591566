import { styled } from '@mui/material';
import { ReactNode } from 'react';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';

type Props = {
  children: ReactNode;
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    fontFamily: 'DM Sans, sans-serif'
  },
  '&.notistack-MuiContent-default': {
    fontFamily: 'DM Sans, sans-serif'
  },
  '&.notistack-MuiContent-warning': {
    fontFamily: 'DM Sans, sans-serif'
  },
  '&.notistack-MuiContent-error': {
    fontFamily: 'DM Sans, sans-serif'
  },
  '&.notistack-MuiContent-info': {
    fontFamily: 'DM Sans, sans-serif'
  }
}));

export function NotistackProvider({ children }: Props) {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent
      }}
      autoHideDuration={2000}
    >
      {children}
    </SnackbarProvider>
  );
}
