export enum NotificationType {
  DownloadSong = 'downloadSong',
  UploadNewVersion = 'uploadNewVersion',
  ListenSong = 'listenSong',
  UploadSong = 'uploadSong'
}

export type Notification = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  folderId?: string;
  folderName?: string;
  songId: string;
  songName: string;
  actionType: NotificationType;
  senderName: string;
  senderEmail: string;
  checked: boolean;
};
