/*
 * virtual-dom hook for rendering the time scale canvas.
 */
export default class {
  tickInfo: any;
  offset: any;
  samplesPerPixel: any;
  duration: any;
  colors: any;
  bpm: any;
  constructor(tickInfo: any, offset: any, samplesPerPixel: any, duration: any, colors: any, bpm: any) {
    this.tickInfo = tickInfo;
    this.offset = offset;
    this.samplesPerPixel = samplesPerPixel;
    this.duration = duration;
    this.colors = colors;
    this.bpm = bpm;
  }

  hook(
    canvas: { width: any; height: any; getContext: (arg0: string) => any },
    prop: any,
    prev: { offset: any; duration: any; samplesPerPixel: any; bpm: any } | undefined
  ) {
    // canvas is up to date
    if (
      prev !== undefined &&
      prev.offset === this.offset &&
      prev.duration === this.duration &&
      prev.samplesPerPixel === this.samplesPerPixel &&
      prev.bpm === this.bpm
    ) {
      return;
    }

    const width = canvas.width;
    const height = canvas.height;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = this.colors.timeColor;
    ctx.clearRect(0, 0, width, height);
    Object.keys(this.tickInfo).forEach(x => {
      const scaleHeight = this.tickInfo[x];
      const scaleY = height - scaleHeight;
      ctx.fillRect(x, scaleY, 1, scaleHeight);
    });
  }
}
