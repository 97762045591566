import { Box, Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

export type SongMetadataProps = {
  isSidebarOpened: boolean;
  toggleSidebar: () => void;
};

export function SongMetadata({ isSidebarOpened, toggleSidebar }: SongMetadataProps) {

  return (
    <Box>
      <Tooltip title={'Expand Metadata'}>
        <Button
          sx={{
            width: '96px',
            height: '28px',
            weight: '400',
            lineHeight: '18px',
            ...(!isSidebarOpened && {
              opacity: 0.5,
              alignItems: 'end'
            })
          }}
          onClick={toggleSidebar}
          disableElevation={true}
          size="small"
          variant="contained"
          color={'secondary'}
        >
          Metadata
          {isSidebarOpened ? (
            <img style={{ marginLeft: 7 }} src="/assets/opened-eye.svg" alt="closed eye" />
          ) : (
            <img style={{ marginLeft: 7 }} src="/assets/closed-eye.svg" alt="closed eye" />
          )}
        </Button>
      </Tooltip>
    </Box>
  );
}
