import { Box, Grid, List, ListItem, ListItemText, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';


export function FAQs() {
  return (
    <Box
      height={1}
      sx={{
        overflow: 'hidden',
      }}
    >
      <SimpleBar style={{ height: `100%`, maxHeight: `calc(100dvh - 56px - 16px)`, overflowY: 'auto', overflowX: 'hidden' }}>
        <Grid container flexGrow={1} height={1} direction={'column'} gap={1} flexWrap={'nowrap'}>
          <Grid item sx={{ background: 'rgba(21, 21, 21, 1)', borderRadius: 1 }} pb={4.5} px={3} pt={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" gap={8}>
                  <Stack
                    direction="row"
                    width={{xs: '100%', sm: '80%'}}
                    height={'51px'}
                    sx={{
                      background: 'rgba(255, 255, 255, 1)',
                      boxShadow: '0px 0px 16.6px 7px rgba(255, 255, 255, 0.25)',
                      borderRadius: 1
                    }}
                    py={1}
                    px={1.25}
                    alignItems={'center'}
                  >
                    <img src="/assets/bookFAQs.png" alt="FAQs book" height={37} width={37} />
                    <Typography color="rgba(0, 0, 0, 1)" fontSize={18} fontWeight={600} ml={6}>
                      How it Works
                    </Typography>
                  </Stack>
                  <Stack display={{ xs: 'none', sm: 'block' }}>
                    <iframe
                      width="100%"
                      height="250"
                      src="https://www.youtube.com/embed/cDgz4zLEnj0?si=IhWUdypu-SuvJzgw"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} pt={2.5}>
                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Drag and drop stems, other music files or folders directly into the Upload page.
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  DEMO supports WAV, MP3, and ZIP folders containing multiple files.
                </Typography>
                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Sign up/Log in to save your files{' '}
                  <span style={{ fontSize: 16, fontWeight: 500, color: '#B9B9B9', lineHeight: '30px' }}>- forget expiring links -</span> and
                  share them by entering an email, and selecting the desired permission level:
                </Typography>
                <List sx={{ listStyleType: 'disc', p: 0, pl: 10, color: '#B9B9B9', marginBottom: 6 }}>
                  <ListItem sx={{ display: 'list-item', p: 0 }}>
                    <ListItemText sx={{ fontWeight: 500, fontSize: 16, fontFamily: 'DM Sans', '& span': { color: '#B9B9B9' } }}>
                      Can Download
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', p: 0 }}>
                    <ListItemText sx={{ fontWeight: 500, fontSize: 16, fontFamily: 'DM Sans', '& span': { color: '#B9B9B9' } }}>
                      Can View - no downloading
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', p: 0 }}>
                    <ListItemText sx={{ fontWeight: 500, fontSize: 16, fontFamily: 'DM Sans', '& span': { color: '#B9B9B9' } }}>
                      Can edit - can add/delete files
                    </ListItemText>
                  </ListItem>
                </List>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Experience your stems in a mini-DAW right on the web. Play, solo, and mute tracks to see how your stems fit together{' '}
                  <span style={{ fontSize: 16, fontWeight: 500, lineHeight: '30px', color: '#B9B9B9' }}>
                    — perfect for showing off your work
                  </span>
                </Typography>
                <></>
              </Grid>
              <Grid item xs={12} display={{ xs: 'block', sm: 'none' }}>
                <iframe
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/cDgz4zLEnj0?si=IhWUdypu-SuvJzgw"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ background: 'rgba(21, 21, 21, 1)', borderRadius: 1 }} pb={4.5} px={3} pt={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" gap={8}>
                  <Stack
                    direction="row"
                    width={{xs: '100%', sm: '80%'}}
                    height={'51px'}
                    sx={{
                      background: 'rgba(255, 255, 255, 1)',
                      boxShadow: '0px 0px 16.6px 7px rgba(255, 255, 255, 0.25)',
                      borderRadius: 1
                    }}
                    py={1}
                    px={1.25}
                    alignItems={'center'}
                  >
                    <img src="/assets/appFAQs.png" alt="FAQs book" height={37} width={37} />
                    <Typography color="rgba(0, 0, 0, 1)" fontSize={18} fontWeight={600} ml={6}>
                      Other Features
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} pt={2.5}>
                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Metadata: Add and view essential metadata like BPM, key, and more.
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  Ensure all vital information is easily accessible and maintained in the same place your music is stored.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Version Control: Keep track of different versions of your projects with ease.
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  Each version is timestamped and can be accessed or reverted to at any time.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Commenting: <span style={{ fontSize: 16, fontWeight: 500, lineHeight: '30px', color: '#B9B9B9' }}>(Coming Soon) </span>
                  You will soon be able to add time-coded comments on tracks directly in the DEMO interface.
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'}>
                  This way, you don’t have to track edits and reviews on a separate platform.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ background: 'rgba(21, 21, 21, 1)', borderRadius: 1 }} pb={4.5} px={3} pt={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" gap={8}>
                  <Stack
                    direction="row"
                    width={{xs: '100%', sm: '80%'}}
                    height={'51px'}
                    sx={{
                      background: 'rgba(255, 255, 255, 1)',
                      boxShadow: '0px 0px 16.6px 7px rgba(255, 255, 255, 0.25)',
                      borderRadius: 1
                    }}
                    py={1}
                    px={1.25}
                    alignItems={'center'}
                  >
                    <img src="/assets/chatFAQs.png" alt="FAQs book" height={37} width={37} />
                    <Typography color="rgba(0, 0, 0, 1)" fontSize={18} fontWeight={600} ml={6}>
                      FAQs
                    </Typography>
                  </Stack>
                  <Stack display={{ xs: 'none', sm: 'block' }}>
                    <iframe
                      width="100%"
                      height="530"
                      src="https://www.youtube.com/embed/1mUDXWtmQSU"
                      title="September 20, 2024"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} pt={2.5}>
                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  What is DEMO?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  DEMO is the quickest and most intuitive way to share stems and other music files. Most composers use DEMO to deliver stems
                  and albums.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  What file types does DEMO support?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  DEMO supports WAV, MP3, as well as ZIP files for bulk uploads and downloads. We’ll render each sub-folder as their own
                  separate batch of stems.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Do links expire?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  No, shared links do not expire as long as you log into your DEMO account. This ensures ongoing access for both you and
                  your collaborators.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Is there a limit to the number of files I can share?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  No, there are no limits to the number of files you can share. However, storage capacity depends on your subscription plan.
                  The first 2 GB are on us.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  How do I get started?
                </Typography>
                <Typography fontSize={16} fontWeight={500} color={'#B9B9B9'} lineHeight={'30px'}>
                  Simply sign up with a user-name and password, verify your email, and begin uploading and sharing your music. It’s that
                  easy!
                </Typography>
              </Grid>

              <Grid item xs={12} display={{ xs: 'block', sm: 'none' }}>
                <iframe
                  width="100%"
                  height="530"
                  src="https://www.youtube.com/embed/1mUDXWtmQSU"
                  title="September 20, 2024"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ background: 'rgba(21, 21, 21, 1)', borderRadius: 1 }} pb={4.5} px={3} pt={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" gap={8}>
                  <Stack
                    direction="row"
                    width={{xs: '100%', sm: '80%'}}
                    height={'51px'}
                    sx={{
                      background: 'rgba(255, 255, 255, 1)',
                      boxShadow: '0px 0px 16.6px 7px rgba(255, 255, 255, 0.25)',
                      borderRadius: 1
                    }}
                    py={1}
                    px={1.25}
                    alignItems={'center'}
                  >
                    <img src="/assets/emailFAQs.png" alt="Email us" height={37} width={37} />
                    <Typography color="rgba(0, 0, 0, 1)" fontSize={18} fontWeight={600} ml={6}>
                      Get in touch
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} pt={2.5}>
                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Questions, need help, feature requests, bug reports, just want to say hi?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'}>
                  Email us: support@demo.studio
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleBar>
    </Box>
  );
}
