import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { DialogCloseButton } from './DialogCloseButton';

type CancelDownloadingDialog = {
  onCancel: () => void;
  onClose: () => void;
  open: boolean;
};

export function CancelDownloadingDialog({ onCancel, onClose, open }: CancelDownloadingDialog) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>Cancel downloading?</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Typography component={'span'} display={'block'}>
            Your files are not finished downloading.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          size="small"
          color="secondary"
          variant="contained"
        >
          Keep downloading
        </Button>
        <Button onClick={onCancel} size="small" color="error" variant="contained">
          Cancel download
        </Button>
      </DialogActions>
    </Dialog>
  );
}
