export type FolderNode = {
  id: string;
  name: string;
  files: File[];
  subFolders: FolderNode[];
};

export const isValidAudioFile = (file: File): boolean => {
  return file.type.startsWith('audio/');
};
export const isZipFile = (file: File): boolean => {
  return file.type === 'application/zip' || file.name.endsWith('.zip');
};
export const readDirectory = (directoryEntry: FileSystemDirectoryEntry): Promise<FolderNode> => {
  return new Promise(resolve => {
    const folder: FolderNode = {
      id: directoryEntry.fullPath,
      name: directoryEntry.name,
      files: [],
      subFolders: []
    };

    const reader = directoryEntry.createReader();

    reader.readEntries(async entries => {
      const entryPromises = entries.map(async entry => {
        if (entry.name !== '.DS_Store') {
          if (entry.isDirectory) {
            const subFolder = await readDirectory(entry as FileSystemDirectoryEntry);
            folder.subFolders.push(subFolder);
          } else {
            const file = await getFile(entry as FileSystemFileEntry);

            folder.files.push(file);
          }
        }
      });

      await Promise.all(entryPromises);
      resolve(folder);
    });
  });
};

export const getFile = (fileEntry: FileSystemFileEntry): Promise<File> => {
  return new Promise(resolve => {
    fileEntry.file(file => {
      resolve(file);
    });
  });
};

const bytesToGigabytes = (bytes: number): number => {
  return bytes / 1024 ** 3; // 1 GB = 1024^3 bytes
};

export const calculateFolderSize = (folders: FolderNode[]): number => {
  let totalSize = 0;

  const calculateSize = (folder: FolderNode) => {
    totalSize += folder.files.reduce((sum, file) => sum + file.size, 0);

    folder.subFolders.forEach(subFolder => calculateSize(subFolder));
  };

  folders.forEach(folder => calculateSize(folder));

  return bytesToGigabytes(totalSize);
};
