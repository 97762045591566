import DoneIcon from '@mui/icons-material/Done';
import { Box, Divider, Grid, MenuItem, Select, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { OrganizationRoles } from '../@types/organizations';
import { AddOrganizationUserDialog } from '../components/AddOrganizationUserDialog';
import { DeleteOrganizationUserDialog } from '../components/DeleteOrganizationUserDialog';
import { useOrganizationsStore } from '../store/organization.store';
import { useUserStore } from '../store/user.store';
import React from 'react';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';

export function Organization() {
  const user = useUserStore(state => state.user);
  const getMembers = useOrganizationsStore(state => state.getOrganizationUsers);
  const members = useOrganizationsStore(state => state.organizationUsers);
  const switchRoleInvitedUser = useOrganizationsStore(state => state.switchRoleInvitedUser);
  const switchRoleMember = useOrganizationsStore(state => state.switchRoleMember);

  const [invitationIdForDeleting, setInvitationIdForDeleting] = useState<string | undefined>(undefined);
  const [memberIdForDeleting, setMemberIdForDeleting] = useState<string | undefined>(undefined);
  const [isAddingMember, setIsAddingMember] = useState(false);

  useEffect(() => {
    if (user?.organizationRole === 'Admin' || user?.organizationRole === 'Owner') {
      getMembers(user.organizationId);
    }
  }, [user]);

  return (
    <Box
      height={1}
      sx={{
        backgroundColor: {
          xs: 'transparent',
          sm: '#151515'
        },
        borderRadius: '8px',
        overflow: 'hidden'
      }}
      px={16}
      pt={7}
      pb={3}
    >
      {isAddingMember && <AddOrganizationUserDialog onClose={() => setIsAddingMember(false)} open={isAddingMember} />}
      {!!(memberIdForDeleting ?? invitationIdForDeleting) && (
        <DeleteOrganizationUserDialog
          open={!!(memberIdForDeleting ?? invitationIdForDeleting)}
          onClose={() => {
            setInvitationIdForDeleting(undefined);
            setMemberIdForDeleting(undefined);
          }}
          invitationId={invitationIdForDeleting}
          userId={memberIdForDeleting}
        />
      )}
      <Grid container direction={'column'}>
        <Grid item mb={15}>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={'auto'}>
              <Typography fontSize={'24px'} lineHeight={'24px'} fontWeight={500}>
                Team members
              </Typography>
            </Grid>
            <Grid item xs={'auto'}>
              <Button
                sx={{ height: '40px', borderRadius: 9 }}
                size="medium"
                color="info"
                variant="contained"
                onClick={() => setIsAddingMember(true)}
              >
                <AddIcon sx={{ fontSize: '18px' }} />
                Add members
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={'column'}>
            <Grid item>
              <Grid container direction="row" mb={1}>
                <Grid item xs={4}>
                  <Typography fontSize={'16px'} lineHeight={'24px'} fontWeight={500}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontSize={'16px'} lineHeight={'24px'} fontWeight={500}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontSize={'16px'} lineHeight={'24px'} fontWeight={500}>
                    Role
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <Divider sx={{ backgroundColor: '#F5F5F5', opacity: 0.1 }} />
              {members.map(m => (
                <React.Fragment key={m.email}>
                  <Grid container direction="row" my={1.5} alignItems={'center'}>
                    <Grid item xs={4}>
                      <Typography fontSize={'14px'} lineHeight={'17px'}>
                        {m?.userName.length ? m?.userName : m?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography fontSize={'14px'} lineHeight={'17px'}>
                        {m.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        MenuProps={{ PaperProps: { sx: { width: '175px' } } }}
                        size="small"
                        defaultValue={m.role}
                        IconComponent={ArrowDown}
                        renderValue={value => {
                          if (OrganizationRoles.Owner === value) {
                            return OrganizationRoles.Owner;
                          }
                          if (OrganizationRoles.Admin === value) {
                            return OrganizationRoles.Admin;
                          }
                          if (OrganizationRoles.Member === value) {
                            return OrganizationRoles.Member;
                          }
                        }}
                        onChange={async e => {
                          if (m.id.length) {
                            await switchRoleMember(
                              user?.organizationId!,
                              m.id,
                              m.role as OrganizationRoles,
                              e.target.value as OrganizationRoles
                            );
                          } else if (m.invitationId.length) {
                            await switchRoleInvitedUser(
                              user?.organizationId!,
                              m.invitationId,
                              user?.name ?? user?.email ?? '',
                              m.email,
                              e.target.value as OrganizationRoles
                            );
                          }
                        }}
                        SelectDisplayProps={{ style: { padding: 0, paddingRight: 32 } }}
                        sx={{
                          p: 0,
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          }
                        }}
                      >
                        <MenuItem value={OrganizationRoles.Owner}>
                          <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                            <Typography>{OrganizationRoles.Owner}</Typography>
                            {m.role === OrganizationRoles.Owner && <DoneIcon fontSize={'small'} />}
                          </Stack>
                        </MenuItem>
                        <MenuItem value={OrganizationRoles.Admin}>
                          <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                            <Typography>{OrganizationRoles.Admin}</Typography>
                            {m.role === OrganizationRoles.Admin && <DoneIcon fontSize={'small'} />}
                          </Stack>
                        </MenuItem>
                        <MenuItem value={OrganizationRoles.Member}>
                          <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                            <Typography>{OrganizationRoles.Member}</Typography>
                            {m.role === OrganizationRoles.Member && <DoneIcon fontSize={'small'} />}
                          </Stack>
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={2} justifyItems={'right'}>
                      <Stack direction="row" width={'100%'} justifyContent={'end'}>
                        <Button
                          disableRipple
                          sx={{ color: 'red' }}
                          onClick={() => {
                            if (m.id) {
                              setMemberIdForDeleting(m.id);
                            } else if (m.invitationId) {
                              setInvitationIdForDeleting(m.invitationId);
                            }
                          }}
                        >
                          {m.id.length ? 'Remove' : 'Revoke invitation'}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider sx={{ backgroundColor: '#F5F5F5', opacity: 0.1 }} />
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
