import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { UploadArea } from './UploadArea';

type UploadButtonProps = {
  onUpload: (files: FileList) => void;
  title: ReactNode;
  multiple?: boolean;
  disabled?: boolean;
};

export default function UploadButton({ title, multiple = false, onUpload, disabled }: UploadButtonProps) {
  return (
    <Typography sx={{ cursor: !disabled ? 'copy' : 'not-allowed' }} component="label" variant="body2" color="text.secondary">
      {title}
      <UploadArea disabled={disabled} multiple={multiple} onUpload={onUpload} />
    </Typography>
  );
}
