import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './components/AuthProvider';
import { NotistackProvider } from './components/NotistackProvider';
import reportWebVitals from './reportWebVitals';
import { GlobalStyles } from './theme/GlobalStyles';
import theme from './theme/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <AuthProvider>
      <NotistackProvider>
        <App />
      </NotistackProvider>
    </AuthProvider>
  </ThemeProvider>
);

reportWebVitals();
