import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { SongVersion } from '../@types/songs';
import { DialogCloseButton } from './DialogCloseButton';

type DeleteSongVersionDialogProps = {
  onClose: () => void;
  version: SongVersion;
  deleteSongVersion: () => void;
  isPending: boolean;
};

export default function DeleteSongVersionDialog({ onClose, version, deleteSongVersion, isPending }: DeleteSongVersionDialogProps) {
  return (
    <Dialog open={!!version} onClose={onClose}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>Delete song version {version.versionNumber}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Typography component={'span'} display={'block'}>
            Warning!
          </Typography>
          If you delete a version of a song, all stems associated with that song version will also be deleted. This can’t be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isPending} onClick={() => onClose()} size="small" color="secondary" variant="contained">
          Cancel
        </Button>
        <Button
          disabled={isPending}
          onClick={() => {
            deleteSongVersion();
            onClose();
          }}
          size="small"
          color="error"
          variant="contained"
        >
          Delete song
        </Button>
      </DialogActions>
    </Dialog>
  );
}
