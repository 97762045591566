import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Avatar,
  AvatarGroup,
  Backdrop,
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Folder, SidebarFolder } from '../../@types/folders';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import authService from '../../services/authService';
import folderService from '../../services/folderService';
import songsService from '../../services/songsService';
import sharedService from '../../services/sharingService';
import { defaultFolderName } from '../../store/folders.store';
import { useFoldersSelector } from '../../store/selectors/folders.selector';
import { useSidebarStore } from '../../store/sidebar.store';
import { useUserStore } from '../../store/user.store';
import CreateDropDownMenu from './Menus/CreateDropDownMenu';
import FolderDropDown from './components/FolderDropDown';
import FolderDropDownItemsList from './components/FolderDropDownItemsList';
import './scrollbar.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import paymentService from '../../services/paymentService';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logoutIcon.svg';
import { ReactComponent as PlansIcon } from '../../assets/icons/plansIcon.svg';

export default function DashboardLayout() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const user = useUserStore(state => state.user);
  const getUser = useUserStore(state => state.getUser);
  const getUsedStorage = useUserStore(state => state.getUsedStorage);
  const usedStorage = useUserStore(state => state.usedStorage);
  const getUserPlan = useUserStore(state => state.getUserPlan);
  const maxSizeStorage = useUserStore(state => state.maxSizeStorage);
  const [movingSongToFolder, setMovingSongToFolder] = useState<null | { folderName: string; songName: string }>(null);
  const { folders, isFoldersLoading, getFolders, defaultFolder, moveFolderSong, setFolders, openFolder, createFolder } =
    useFoldersSelector();
  const containerRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();
  const { folderId, songId } = useParams();
  const location = useLocation();
  const [highlightedSong, setHighlightedSong] = useState('');
  const [isBackdrop, setIsBackdrop] = useState(false);
  const isSidebarOpened = useSidebarStore(state => state.isSidebarOpened);
  const toggleSidebarMobile = useSidebarStore(state => state.toggleSidebar);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user: userAuth0, logout, loginWithRedirect } = useAuth0();
  const [isEmailVerified, setIsEmailVerified] = useState<undefined | boolean>(undefined);
  const isPublicLocation = location.pathname.includes('public');
  const params = new URLSearchParams(location.search);
  const hasMounted = useRef(false);
  let storageLimit = maxSizeStorage > 1000 ? maxSizeStorage / 1000 + ' TB' : maxSizeStorage + ' GB';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const invitation = searchParams.get('invitation');
    const organization = searchParams.get('organization');

    if (invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          invitation,
          organization,
          redirectUri: window.location.origin,
          appState: {
            returnTo: '/dashboard'
          }
        }
      });
    }
  }, [location, loginWithRedirect]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (user || userAuth0) {
      getUserPlan();
      getUsedStorage();
      timeout = setInterval(() => getUsedStorage(), 5000);
    }

    return () => clearTimeout(timeout);
  }, [userAuth0, user]);

  useEffect(() => {
    if (!isPublicLocation) {
      getUser();
      setFolders([]);

      if (userAuth0) {
        authService.checkEmailVerification().then(emailVerification => setIsEmailVerified(emailVerification));
      }

      getFolders().then(async folders => {
        if (!folders.filter(f => f.name !== 'example').length) {
          await createFolder(defaultFolderName, undefined, user?.name || user?.email);

          if (user) {
            navigate('/dashboard');
          }
        }
      });
    }
  }, [userAuth0, isPublicLocation]);

  useEffect(() => {
    if (typeof isEmailVerified === 'boolean' && !isEmailVerified) {
      navigate('/email-verification');
    }
  }, [isEmailVerified]);

  useEffect(() => {
    const isFolderShare = params.get('isfs');

    if (isFolderShare) {
      localStorage.setItem('isfs', isFolderShare);
    }

    if (isPublicLocation && folderId && isFolderShare !== 'false') {
      sharedService.getFolderAccessType(folderId).then(accessType => {
        folderService.getFolderById(folderId).then(folder => {
          const addIsOpenProperty = (folders: Folder[]): SidebarFolder[] => {
            return folders.map(folder => ({
              ...folder,
              isOpen: false,
              children: addIsOpenProperty(folder.children)
            }));
          };

          const modifiedFolders = addIsOpenProperty([folder]);

          function findFirstSong(folders: SidebarFolder[]): { folderId: string; songId: string } | null {
            for (const folder of folders) {
              if (folder.songs.length > 0) {
                return { folderId: folder.id, songId: folder.songs[0].id };
              }

              if (folder.children.length > 0) {
                const result = findFirstSong(folder.children);
                if (result) {
                  return result;
                }
              }
            }

            return null;
          }

          const firstSong = findFirstSong(modifiedFolders);

          setFolders(modifiedFolders.map(f => ({ ...f, name: f.name, isOpen: true })));
          if (firstSong) {
            navigate(`/dashboard/public/${firstSong.folderId}/${firstSong.songId}`);
            setHighlightedSong(firstSong?.songId);
          }
        });
      });
    } else if (isPublicLocation && songId && isFolderShare !== 'true') {
      songsService.getSongById(songId!).then(song => {
        setFolders([{ id: 'default_folder_for_uploads', name: 'default_folder_for_uploads', isOpen: false, children: [], songs: [song] }]);
        setHighlightedSong(song.id ?? '');
      });
    }
  }, [isPublicLocation]);

  useEffect(() => {
    if (params.get('open') === 'true') {
      setIsSidebarHidden(false);

      if (folders) {
        if (folderId) openFolder(folderId);
      }
    }
  }, [location, folders.length]);

  useEffect(() => {
    if (hasMounted.current) {
      toggleSidebarMobile();
    } else {
      hasMounted.current = true;
    }
  }, [location.pathname]);

  const handleDropMusicToDefaultFolder = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const draggedData = JSON.parse(e.dataTransfer.getData('text/plain'));

    if (defaultFolder && draggedData.folderId === defaultFolder.id) return;

    setMovingSongToFolder({ songName: draggedData.songName, folderName: 'root' });
    await moveFolderSong(
      { songId: draggedData.songId, currentFolderId: draggedData.folderId, newFolderId: defaultFolder!.id },
      user?.name || user?.email
    );

    enqueueSnackbar('Song moved successfully', { variant: 'success' });

    setMovingSongToFolder(null);
  };

  const toggleSidebar = () => {
    setIsSidebarHidden(!isSidebarHidden);
  };

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={!!movingSongToFolder}
        sx={{
          '& .MuiSnackbarContent-root': {
            background: 'linear-gradient(270deg, #008EF3 0%, #16E3F5 100%)'
          }
        }}
        message={"Moving song '" + movingSongToFolder?.songName + "' to " + "'" + movingSongToFolder?.folderName + "'" + ' folder...'}
      />
      <Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={isBackdrop}>
        <CircularProgress sx={{ color: '#008EF3' }} />
      </Backdrop>
      {isMobile ? (
        <>
          <Drawer
            open={isSidebarOpened}
            onClose={toggleSidebarMobile}
            PaperProps={{ sx: { backgroundColor: '#151515', backgroundImage: 'unset' } }}
          >
            <Box
              sx={{
                width: 340,
                height: '100%',
                maxHeight: '100dvh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
                position: 'relative'
              }}
              role="presentation"
            >
              <SimpleBar
                style={{
                  maxHeight: 'calc(100dvh - 10%)',
                  width: '100%'
                }}
              >
                <List>
                  <Grid container p={1.25}>
                    <Grid item xs>
                      <Grid container columnSpacing={2.25} alignItems={'center'}>
                        <Grid item xs={'auto'}>
                          <AvatarGroup max={2}>
                            <Avatar sx={{ width: 28, height: 28 }}>
                              <AccountCircleIcon />
                            </Avatar>
                          </AvatarGroup>
                        </Grid>
                        <Grid item xs>
                          <Stack direction={'column'}>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={16}
                              sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            >
                              {userAuth0?.name ?? userAuth0?.email}
                            </Typography>
                            {userAuth0?.email && userAuth0.name !== userAuth0?.email ? (
                              <Typography fontSize={14} color="text.secondary">
                                {userAuth0?.email}
                              </Typography>
                            ) : (
                              <Typography onClick={() => loginWithRedirect()}> Log in/Sign up</Typography>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={'auto'}></Grid>
                  </Grid>
                  <Divider />
                  <Box>
                    <ListItem disablePadding>
                      <ListItemButton sx={{ paddingBlock: 1, paddingInline: 2 }} onClick={() => navigate('/dashboard')}>
                        <UploadIcon width={24} height={24} />
                        <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                          Upload
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton sx={{ paddingBlock: 1, paddingInline: 2 }} onClick={() => navigate('/faqs')}>
                        <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                          How it works/FAQs
                        </Typography>
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton sx={{ paddingBlock: 1, paddingInline: 2 }} onClick={() => navigate('/plans')}>
                        <ListItemIcon style={{ minWidth: 'unset', marginRight: '4px' }}>
                          <PlansIcon width={24} height={24} />
                        </ListItemIcon>
                        <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                          Plans
                        </Typography>
                      </ListItemButton>
                    </ListItem>

                    {userAuth0 && (
                      <ListItem disablePadding>
                        <ListItemButton sx={{ paddingBlock: 1, paddingInline: 2 }} onClick={handleLogout}>
                          <ListItemIcon style={{ minWidth: 'unset', marginRight: '4px' }}>
                            <LogoutIcon width={24} height={24} />
                          </ListItemIcon>
                          <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                            Log out
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    )}
                  </Box>
                  <Divider />
                  <Stack sx={{ backgroundColor: '#151515' }} height={1} borderRadius={2} flexDirection="column">
                    <Stack p={2} flexGrow={1}>
                      <CreateDropDownMenu />
                      <Grid container direction={'column'} flexGrow={1} mt={2.5}>
                        <Box ref={containerRef} />
                        {isFoldersLoading ? (
                          <Grid item>
                            <Typography mt={1}>Loading...</Typography>
                          </Grid>
                        ) : null}
                        <Grid rowSpacing={1.25} container direction={'column'}>
                          {folders?.map(folder => (
                            <Grid item key={folder.id} maxWidth={'100% !important'}>
                              <FolderDropDown
                                folder={folder}
                                setMovingSongToFolder={setMovingSongToFolder}
                                highlightedSongId={highlightedSong}
                                setHighlightedSongId={setHighlightedSong}
                                isSharingFolder={folder.id === 'shared_folder' || isPublicLocation}
                                isPublicLocation={isPublicLocation}
                                setIsBackdrop={setIsBackdrop}
                              />
                            </Grid>
                          ))}
                          <Grid
                            maxWidth={'100% !important'}
                            item
                            onDragOver={e => e.preventDefault()}
                            onDrop={handleDropMusicToDefaultFolder}
                          >
                            {defaultFolder ? (
                              <FolderDropDownItemsList
                                highlightedSongId={highlightedSong}
                                setHighlightedSongId={setHighlightedSong}
                                folder={defaultFolder!}
                                isSharingFiles={isPublicLocation}
                              />
                            ) : null}
                          </Grid>
                        </Grid>

                        <Grid onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder} flexGrow={1}></Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </List>
              </SimpleBar>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                position={'absolute'}
                bottom={10}
                sx={{ backgroundColor: '#151515' }}
              >
                <Grid item xs={6}>
                  <Typography sx={{ padding: '8px 8px', opacity: '50%', fontSize: '14px' }}>
                    {usedStorage} GB of {storageLimit} used
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <ButtonBase
                    sx={{
                      py: 0.875,
                      px: 1.5,
                      border: '1px solid #494949',
                      borderRadius: 1,
                      background: `linear-gradient(#131414 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
                      borderImage: 'linear-gradient(#008EF3, #16E1F5)'
                    }}
                    onClick={event => {
                      event.preventDefault();

                      navigate('/plans');
                    }}
                  >
                    <Typography
                      color="transparent"
                      sx={{
                        fontSize: '14px',
                        background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.9) 88.47%)',
                        '-webkit-background-clip': 'text',
                        backgroundClip: 'text'
                      }}
                    >
                      Get more storage
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
          <Outlet />
        </>
      ) : null}
      {!isMobile ? (
        <Grid container spacing={1} flexGrow={1} margin={0}>
          {!isSidebarHidden ? (
            <Grid item sx={{ width: '327px' }} pb={1}>
              <Stack sx={{ backgroundColor: '#151515' }} height={1} borderRadius={2} flexDirection="column">
                <Stack p={2} flexGrow={1}>
                  <CreateDropDownMenu />
                  <Grid maxWidth={'100% !important'} item onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder}>
                    {folders.filter(f => f.name === 'example').length ? (
                      <FolderDropDownItemsList
                        highlightedSongId={highlightedSong}
                        setHighlightedSongId={setHighlightedSong}
                        folder={folders.filter(f => f.name === 'example').at(0)!}
                        isSharingFiles={isPublicLocation}
                      />
                    ) : null}
                  </Grid>
                  <Grid container direction={'column'} flexGrow={1} mt={1.25}>
                    <Box ref={containerRef} />
                    {isFoldersLoading ? (
                      <Grid item>
                        <Typography mt={1}>Loading...</Typography>
                      </Grid>
                    ) : null}
                    <SimpleBar
                      style={{
                        maxHeight: 'calc(100vh - 230px)',
                        width: '100%'
                      }}
                    >
                      <Grid rowSpacing={1.25} container direction={'column'}>
                        {folders
                          ?.filter(f => f.name !== 'example')
                          ?.map(folder => (
                            <Grid item key={folder.id} maxWidth={'100% !important'}>
                              <FolderDropDown
                                folder={folder}
                                setMovingSongToFolder={setMovingSongToFolder}
                                highlightedSongId={highlightedSong}
                                setHighlightedSongId={setHighlightedSong}
                                isSharingFolder={folder.id === 'shared_folder' || isPublicLocation}
                                isPublicLocation={isPublicLocation}
                                setIsBackdrop={setIsBackdrop}
                              />
                            </Grid>
                          ))}

                        <Grid
                          maxWidth={'100% !important'}
                          item
                          onDragOver={e => e.preventDefault()}
                          onDrop={handleDropMusicToDefaultFolder}
                        >
                          {defaultFolder ? (
                            <FolderDropDownItemsList
                              highlightedSongId={highlightedSong}
                              setHighlightedSongId={setHighlightedSong}
                              folder={defaultFolder!}
                              isSharingFiles={isPublicLocation}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </SimpleBar>
                    <Grid onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder} flexGrow={1}></Grid>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={'auto'}>
                      <Typography sx={{ padding: '4px 8px', opacity: '50%', fontSize: '12px' }}>
                        {usedStorage} GB of {storageLimit} used
                      </Typography>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <ButtonBase
                        sx={{
                          py: 0.875,
                          px: 1.5,
                          border: '1px solid #494949',
                          borderRadius: 1,
                          background: `linear-gradient(#131414 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
                          borderImage: 'linear-gradient(#008EF3, #16E1F5)'
                        }}
                        onClick={event => {
                          event.preventDefault();

                          navigate('/plans');
                        }}
                      >
                        <Typography
                          color="transparent"
                          sx={{
                            fontSize: '14px',
                            background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.9) 88.47%)',
                            '-webkit-background-clip': 'text',
                            backgroundClip: 'text'
                          }}
                        >
                          Get more storage
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>{' '}
                </Stack>
              </Stack>
            </Grid>
          ) : null}
          <Grid item sx={{ width: isSidebarHidden ? '100%' : 'calc(100% - 327px)' }} pr={2} pb={1}>
            <Stack height={1} borderRadius={1}>
              <Tooltip title={'Files & Folders'}>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  sx={{
                    position: 'absolute',
                    left: '30px',
                    top: '88%',
                    zIndex: 999,
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#000000'
                  }}
                  onClick={() => {
                    toggleSidebar();
                    toggleSidebarMobile();
                  }}
                >
                  {isSidebarHidden ? <ArrowForwardIosIcon fontSize={'small'} /> : <ArrowBackIosNewIcon fontSize={'small'} />}
                </IconButton>
              </Tooltip>
              <Outlet />
            </Stack>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
