import { Button } from '@mui/material';
import React from 'react';

type PlansButtonProps = {
  billing: 'Yearly' | 'Monthly';
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
};

export const PlansButton = ({ billing, onClick, children }: PlansButtonProps) => {
  return (
    <Button
      sx={{
        padding: '4px 8px',
        width: '107px',
        borderRadius: 38,
        height: '33px',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#FFFFFF',
        backgroundColor: billing === children ? '#008EF3' : '#494949',
        '&:hover': {
          backgroundColor: billing === children ? '#006DBA' : '#363636'
        }
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
