import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useSongSelector } from '../store/selectors/song.selector';
import { DialogCloseButton } from './DialogCloseButton';

type DeleteSongDialogProps = {
  songId: string;
  folderId: string;
  songName: string;
  onClose: () => void;
  open: boolean;
};

export default function DeleteSongDialog({ songId, folderId, songName, onClose, open }: DeleteSongDialogProps) {
  const navigate = useNavigate();
  const { deleteSong, isDeletingSong } = useSongSelector();
  const { deleteFolderSong } = useFoldersSelector();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteSong = async () => {
    await deleteSong(songId);
    enqueueSnackbar('Song deleted successfully', { variant: 'success' });

    onClose();
    navigate('/dashboard');
    deleteFolderSong(folderId, songId);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>Delete Song "{songName}"</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Typography component={'span'} display={'block'}>
            Warning!
          </Typography>
          This will also delete all stems affiliated with the song. This can’t be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isDeletingSong} onClick={() => onClose()} size="small" color="secondary" variant="contained">
          Cancel
        </Button>
        <Button disabled={isDeletingSong} onClick={() => handleDeleteSong()} size="small" color="error" variant="contained">
          Delete song
        </Button>
      </DialogActions>
    </Dialog>
  );
}
