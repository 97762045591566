import { Notification, NotificationType } from '../@types/notifications';
import axios from '../utils/axios';

const getNotifications = async (): Promise<{ notCheckedNotificationLength: number; notifications: Notification[] }> => {
  return await axios.get<{ notCheckedNotificationLength: number; notifications: Notification[] }>(`notifications`).then(data => data.data);
};

const createNotification = async (actionType: NotificationType, songId: string, folderId?: string): Promise<void> => {
  await axios.post('notifications/create-notification', { actionType, songId, folderId });
};

const checkNotification = async (): Promise<void> => {
  await axios.post('notifications/check-notifications');
};

const notificationService = { getNotifications, checkNotification, createNotification };

export default notificationService;
