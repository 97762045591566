// @ts-ignore
import { LoudnessMeter } from '@domchristie/needles';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
export function LufsItem({ audioBuffer, ac }: { audioBuffer: AudioBuffer; ac: AudioContext }) {
  const [integratesLufs, setIntegratesLufs] = useState(undefined);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobileLandscape = useMediaQuery('(max-height: 500px) and (orientation: landscape)');
  useEffect(() => {
    if (!audioBuffer) {
      return undefined;
    }
    let source = ac.createBufferSource();
    var loudnessMeter = new LoudnessMeter({
      source,
      workerUri: new URL('@domchristie/needles/dist/needles-worker.js', import.meta.url),
      modes: ['integrated'],
      offline: true
    });
    function listener(event: any) {
      setIntegratesLufs(event.data.value);
    }
    loudnessMeter.on('dataavailable', listener);
    loudnessMeter.start();
    loudnessMeter.input(audioBuffer);
    return () => {
      loudnessMeter.off('dataavailable', listener);
      loudnessMeter.stop();
    };
  }, [audioBuffer]);

  return (
    <>
      <Stack
        sx={{
          background: '#1F1F1F',
          maxHeight: isMobile || isMobileLandscape ? '115px' : '60px',
          minHeight: isMobile || isMobileLandscape ? '115px' : '60px',
          color: 'white',
          fontSize: '12px',
          lineHeight: '15.62px',
          fontFamily: 'DM Sans, sans-serif',
          borderRight: '1px solid #494949', 
        }}
        direction="row"
        py={1.5}
        pl={isTablet ? 1.25 : 3}
        pr={isTablet ? 1.25 : 2}
        justifyContent="center"
        alignItems="center"
      >
        {integratesLufs && Math.round(integratesLufs * 100) / 100}
      </Stack>
      <Box height="1px" width="100%" sx={{ backgroundColor: '#494949' }} />
    </>
  );
}
