export type MetadataAlbumTitle = {
  id: string;
  albumTitle: string;
};

export type MetadataGenre = {
  id: string;
  genre: string;
};

export type MetadataComposer = {
  id: string;
  name: string;
  ownershipRate: number;
};

export type MetadataPublisher = {
  id: string;
  name: string;
  ownershipRate: number;
};

export type MetadataArtist = {
  id: string;
  name: string;
};

export type MetadataISRCCode = {
  id: string;
  code: string;
};

export type MetadataUPCCode = {
  id: string;
  code: string;
};

export type MetadataLyric = {
  id: string;
  lyrics: string;
};

export enum MetadataSampleRateValues {
  FortyFourPointOne = 44.1,
  FortyEight = 48,
  EightyEightPointTwo = 88.2,
  NinetySix = 96,
  HundredAndSeventySixPointFour = 176.4,
  HundredAndNinetyTwo = 192
}

export enum MetadataBitDepthValues {
  Sixteen = 16,
  TwentyFour = 24,
  Thirty = 32
}

export type SongMetadata = {
  albumTitles: MetadataAlbumTitle[];
  genres: MetadataGenre[];
  artists: MetadataArtist[];
  composers: MetadataComposer[];
  publishers: MetadataPublisher[];
  ISRCCodes: MetadataISRCCode[];
  UPCCodes: MetadataUPCCode[];
  lyrics: MetadataLyric[];
  sampleRate: MetadataSampleRateValues;
  bitDepth: MetadataBitDepthValues;
};

export type SongMetadataResponse = {
  albumTitles: MetadataAlbumTitle[];
  genres: MetadataGenre[];
  artists: MetadataArtist[];
  composers: MetadataComposer[];
  publishers: MetadataPublisher[];
  ISRCCodes: MetadataISRCCode[];
  UPCCodes: MetadataUPCCode[];
  lyrics: MetadataLyric[];
  sampleRate: { sampleRate: MetadataSampleRateValues };
  bitDepth: { bitDepth: MetadataBitDepthValues };
};
