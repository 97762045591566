import h from 'virtual-dom/h';
import TimescaleHook from './TimescaleHook';
function secondsToPixels(seconds: number, resolution: number, sampleRate: number) {
  return Math.ceil((seconds * sampleRate) / resolution);
}

const timeinfo: any = {
  20000: {
    marker: 30000,
    bigStep: 10000,
    smallStep: 5000,
    secondStep: 5
  },
  12000: {
    marker: 15000,
    bigStep: 5000,
    smallStep: 1000,
    secondStep: 1
  },
  10000: {
    marker: 10000,
    bigStep: 5000,
    smallStep: 1000,
    secondStep: 1
  },
  5000: {
    marker: 5000,
    bigStep: 1000,
    smallStep: 500,
    secondStep: 1 / 2
  },
  2500: {
    marker: 2000,
    bigStep: 1000,
    smallStep: 500,
    secondStep: 1 / 2
  },
  1500: {
    marker: 2000,
    bigStep: 1000,
    smallStep: 200,
    secondStep: 1 / 5
  },
  700: {
    marker: 1000,
    bigStep: 500,
    smallStep: 100,
    secondStep: 1 / 10
  }
};
export enum TimeScaleType {
  SECONDS,
  BITS
}
class TimeScale {
  duration: number;
  offset: number;
  samplesPerPixel: number;
  sampleRate: number;
  marginLeft: number;
  bpm: number;
  type: TimeScaleType = TimeScaleType.BITS;
  colors: any;
  constructor(duration: number, offset: number, samplesPerPixel: number, sampleRate: number, colors: any, marginLeft = 0, bpm: number = 0) {
    this.duration = duration;
    this.offset = offset;
    this.samplesPerPixel = samplesPerPixel;
    this.sampleRate = sampleRate;
    this.marginLeft = marginLeft;
    this.bpm = bpm;
    this.colors = colors;
  }
  getScaleInfo(resolution: number) {
    let keys = Object.keys(timeinfo).map(item => parseInt(item, 10));

    // make sure keys are numerically sorted.
    keys = keys.sort((a, b) => a - b);

    for (let i = 0; i < keys.length; i += 1) {
      if (resolution <= keys[i]) {
        return timeinfo[keys[i]];
      }
    }

    return timeinfo[keys[0]];
  }
  /*
      Return time in format mm:ss
    */
  static formatTime(milliseconds: number) {
    const seconds = milliseconds / 1000;
    let s: string | number = seconds % 60;
    const m = (seconds - s) / 60;

    if (s < 10) {
      s = `0${s}`;
    }

    return `${m}:${s}`;
  }

  renderBits() {
    const widthX = secondsToPixels(this.duration, this.samplesPerPixel, this.sampleRate);
    const pixPerSec = this.sampleRate / this.samplesPerPixel;
    const bitsPerSec = this.bpm / 60;
    const pixOffset = secondsToPixels(this.offset, this.samplesPerPixel, this.sampleRate);
    const scaleInfo = this.getScaleInfo(this.samplesPerPixel);
    const bitStep = Math.pow(2, Math.ceil(Math.log((scaleInfo.marker / 1000) * bitsPerSec) / Math.log(2)));

    let smallMarkerCount = 0;

    const pixPerBit = pixPerSec / bitsPerSec;
    for (let j of [2, 4, 6]) {
      if ((pixPerBit * bitStep) / j > 20) {
        smallMarkerCount = j - 1;
      }
    }
    const canvasInfo: { [key: number]: number } = {};
    const timeMarkers = [];
    const end = widthX + pixOffset;
    let counter = 0;
    for (let i = 0; i < end; i += pixPerBit * bitStep) {
      const pixIndex = Math.floor(i);
      const pix = pixIndex - pixOffset;
      timeMarkers.push(
        h(
          'div.time',
          {
            attributes: {
              style: `position: absolute; left: ${pix}px;`
            }
          },
          [counter + '']
        )
      );

      let smallPix = pix;
      let smallMarkerStep = Math.ceil((Math.floor(i + pixPerBit * bitStep) - pixOffset - pix) / (smallMarkerCount + 1));
      for (let j = 0; j < smallMarkerCount; j++) {
        smallPix += smallMarkerStep;
        canvasInfo[smallPix] = 5;
      }
      canvasInfo[pix] = 10;
      counter += bitStep;
    }

    return h(
      'div.playlist-time-scale',
      {
        attributes: {
          style: `position: relative; left: 0; right: 0; margin-left: ${this.marginLeft}px;`
        }
      },
      [
        timeMarkers,
        widthX < 32000
          ? [
              h(
                'canvas',
                {
                  attributes: {
                    width: widthX + '',
                    height: 30 + '',
                    style: 'position: absolute; left: 0; right: 0; top: 0; bottom: 0;'
                  },
                  hook: new TimescaleHook(canvasInfo, this.offset, this.samplesPerPixel, this.duration, this.colors, this.bpm)
                },
                []
              )
            ]
          : []
      ]
    );
  }
  renderSecs() {
    const widthX = secondsToPixels(this.duration, this.samplesPerPixel, this.sampleRate);
    const pixPerSec = this.sampleRate / this.samplesPerPixel;
    const pixOffset = secondsToPixels(this.offset, this.samplesPerPixel, this.sampleRate);
    const scaleInfo = this.getScaleInfo(this.samplesPerPixel);
    const canvasInfo: { [key: number]: number } = {};
    const timeMarkers = [];
    const end = widthX + pixOffset;
    let counter = 0;
    for (let i = 0; i < end; i += pixPerSec * scaleInfo.secondStep) {
      const pixIndex = Math.floor(i);
      const pix = pixIndex - pixOffset;

      if (pixIndex >= pixOffset) {
        // put a timestamp every 30 seconds.
        if (scaleInfo.marker && counter % scaleInfo.marker === 0) {
          timeMarkers.push(
            h(
              'div.time',
              {
                attributes: {
                  style: `position: absolute; left: ${pix}px;`
                }
              },
              [TimeScale.formatTime(counter)]
            )
          );
          canvasInfo[pix] = 10;
        } else if (scaleInfo.bigStep && counter % scaleInfo.bigStep === 0) {
          canvasInfo[pix] = 5;
        } else if (scaleInfo.smallStep && counter % scaleInfo.smallStep === 0) {
          canvasInfo[pix] = 2;
        }
      }

      counter += 1000 * scaleInfo.secondStep;
    }

    return h(
      'div.playlist-time-scale',
      {
        attributes: {
          style: `position: relative; left: 0; right: 0; margin-left: ${this.marginLeft}px;`
        }
      },
      [
        timeMarkers,
        widthX < 32000
          ? [
              h(
                'canvas',
                {
                  attributes: {
                    width: widthX + '',
                    height: 30 + '',
                    style: 'position: absolute; left: 0; right: 0; top: 0; bottom: 0;'
                  },
                  hook: new TimescaleHook(canvasInfo, this.offset, this.samplesPerPixel, this.duration, this.colors, this.bpm)
                },
                []
              )
            ]
          : []
      ]
    );
  }
  render() {
    if (!this.bpm) {
      return this.renderSecs();
    }
    switch (this.type) {
      case TimeScaleType.BITS:
        return this.renderBits();
      case TimeScaleType.SECONDS:
        return this.renderSecs();
    }
  }
}

export default TimeScale;
