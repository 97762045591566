import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useOrganizationsStore } from '../store/organization.store';
import { useUserStore } from '../store/user.store';
import { DialogCloseButton } from './DialogCloseButton';

type DeleteOrganizationUserDialogProps = {
  invitationId?: string;
  userId?: string;
  onClose: () => void;
  open: boolean;
};

export function DeleteOrganizationUserDialog({ invitationId, userId, open, onClose }: DeleteOrganizationUserDialogProps) {
  const user = useUserStore(state => state.user);
  const deleteMember = useOrganizationsStore(state => state.deleteMember);
  const deleteInvitation = useOrganizationsStore(state => state.deleteInvitation);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle> {userId?.length ? 'Remove' : 'Revoke invitation'}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Are you sure you want to {userId?.length ? 'remove the user' : 'revoke invitation'}? The user will loose access to the
          organization.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} size="small" color="secondary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if (invitationId) {
              await deleteInvitation(user?.organizationId!, invitationId);
            } else if (userId) {
              await deleteMember(user?.organizationId!, userId);
            }

            onClose();
          }}
          size="small"
          color="error"
          variant="contained"
        >
          {userId?.length ? 'Remove' : 'Revoke invitation'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
