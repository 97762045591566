import { useAuth0 } from '@auth0/auth0-react';
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { OrganizationRoles } from '../@types/organizations';
import { useOrganizationsStore } from '../store/organization.store';
import { useUserStore } from '../store/user.store';
import { ValidationPatterns } from '../utils/validationPatterns';
import { DialogCloseButton } from './DialogCloseButton';
import { ReactComponent as Choice } from '../assets/icons/choice.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';

type AddOrganizationUserDialogProps = {
  onClose: () => void;
  open: boolean;
};

export function AddOrganizationUserDialog({ open, onClose }: AddOrganizationUserDialogProps) {
  const { user: auth0User } = useAuth0();
  const user = useUserStore(state => state.user);
  const inviteUser = useOrganizationsStore(state => state.inviteUser);

  const [isRoleOpen, setIsRoleOpen] = useState(false);
  const [selectedType, setSelectedType] = React.useState('');

  const validationSchema = Yup.object({
    emails: Yup.array()
      .of(Yup.string().required('Email is required').matches(ValidationPatterns.email, 'Please enter a valid email'))
      .min(1, 'At least one email is required')
      .test('unique-emails', 'Emails should be unique', value => {
        if (!value) return true;
        const uniqueEmails = new Set(value);
        return uniqueEmails.size === value.length;
      }),
    role: Yup.string().required().oneOf(Object.values(OrganizationRoles), 'Invalid access type')
  });

  const formik = useFormik({
    initialValues: {
      emails: [],
      role: OrganizationRoles.Member
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      for (let email of values.emails) {
        await inviteUser(user?.organizationId!, auth0User?.name ?? auth0User?.email ?? '', email, values.role);
      }

      formikHelpers.resetForm();
    }
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <DialogCloseButton onClose={onClose} />
          <DialogTitle>Invite Team Members</DialogTitle>
          <DialogContent sx={{ pb: 8 }}>
            <Typography sx={{ opacity: 0.7 }} fontSize={'14px'} fontWeight={400} mb={4.25}>
              Select a role and enter the email addresses of the people you want to invite
            </Typography>
            <Typography fontSize={'14px'} fontWeight={500} mb={1.5}>
              Role
            </Typography>
            <Select
              fullWidth
              onClose={() => {
                setIsRoleOpen(false);
              }}
              onOpen={() => {
                setIsRoleOpen(true);
              }}
              open={isRoleOpen}
              {...getFieldProps('role')}
              IconComponent={ArrowDown}
              renderValue={selected => {
                switch (selected) {
                  case OrganizationRoles.Owner:
                    setSelectedType(OrganizationRoles.Owner);
                    return OrganizationRoles.Owner;
                  case OrganizationRoles.Admin:
                    setSelectedType(OrganizationRoles.Admin);
                    return OrganizationRoles.Admin;
                  case OrganizationRoles.Member:
                    setSelectedType(OrganizationRoles.Member);
                    return OrganizationRoles.Member;
                  default:
                    return '';
                }
              }}
              sx={{
                color: '#ABABAB',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                padding: '0px',
                borderRadius: '8px',
                '& .MuiSelect-select': {
                  fontSize: '14px !important',
                  paddingY: '7.5px',
                  borderRadius: '8px'
                },
                '& .MuiInputBase-root': {
                  borderRadius: '8px'
                }
              }}
            >
              <MenuItem
                sx={{
                  position: 'relative',
                  fontSize: '14px',
                  paddingTop: '3px',
                  paddingLeft: '13px',
                  display: 'flex',
                  alignItems: 'flex-start'
                }}
                value={OrganizationRoles.Owner}
              >
                {OrganizationRoles.Owner}
                <Typography sx={{ fontSize: '11px', color: '#757575', position: 'absolute', top: '50%', left: '13px' }}>
                  Full access to shared files, billing information, and team settings
                </Typography>
                {selectedType === OrganizationRoles.Owner ? (
                  <Stack
                    sx={{
                      position: 'absolute',
                      right: '5px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px'
                    }}
                  >
                    <Choice opacity={'50%'} />
                  </Stack>
                ) : null}
              </MenuItem>
              <MenuItem
                sx={{
                  position: 'relative',
                  fontSize: '14px',
                  paddingTop: '3px',
                  paddingLeft: '13px',
                  display: 'flex',
                  alignItems: 'flex-start'
                }}
                value={OrganizationRoles.Admin}
              >
                {OrganizationRoles.Admin}
                <Typography sx={{ fontSize: '11px', color: '#757575', position: 'absolute', top: '50%', left: '13px' }}>
                  Full access to shared files and team settings
                </Typography>
                {selectedType === OrganizationRoles.Admin ? (
                  <Stack
                    sx={{
                      position: 'absolute',
                      right: '5px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px'
                    }}
                  >
                    <Choice opacity={'50%'} />
                  </Stack>
                ) : null}
              </MenuItem>
              <MenuItem
                sx={{
                  position: 'relative',
                  fontSize: '14px',
                  paddingTop: '3px',
                  paddingLeft: '13px',
                  display: 'flex',
                  alignItems: 'flex-start'
                }}
                value={OrganizationRoles.Member}
              >
                {OrganizationRoles.Member}
                <Typography sx={{ fontSize: '11px', color: '#757575', position: 'absolute', top: '50%', left: '13px' }}>
                  Full access to shared files
                </Typography>
                {selectedType === OrganizationRoles.Member ? (
                  <Stack
                    sx={{
                      position: 'absolute',
                      right: '5px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px'
                    }}
                  >
                    <Choice opacity={'50%'} />
                  </Stack>
                ) : null}
              </MenuItem>
            </Select>

            <Typography fontSize={'14px'} fontWeight={500} mt={3.75} mb={1.5}>
              Emails
            </Typography>

            <Autocomplete
              multiple
              freeSolo
              options={[]}
              noOptionsText
              open={false}
              value={formik.values.emails}
              onChange={(event, newValue) => formik.setFieldValue('emails', newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => {
                      const updatedEmails = formik.values.emails.filter(email => email !== option);
                      formik.setFieldValue('emails', updatedEmails);
                    }}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="name@example.com"
                  error={Boolean(formik.touched.emails && formik.errors.emails)}
                  helperText={formik.touched.emails && formik.errors.emails ? Array.from(new Set(formik.errors.emails)).join(', ') : ''}
                  onBlur={() => formik.setFieldTouched('emails', true)}
                  sx={{
                    color: '#ABABAB',
                    boxShadow: 'none',
                    padding: '0px',
                    '& .MuiInputBase-root': {
                      paddingY: '2.5px',
                      fontSize: '14px !important',
                      borderRadius: '8px',
                      backgroundColor: 'transparent'
                    }
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions sx={{ pb: 3.5, pl: 3 }}>
            <Button
              sx={{
                borderRadius: 0,
                height: '40px',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#FFFFFF',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              sx={{
                padding: '4px 8px',
                width: '128px',
                borderRadius: 38,
                height: '40px',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#FFFFFF',
                backgroundColor: '#008EF3',
                '&:hover': {
                  backgroundColor: '#006DBA'
                }
              }}
            >
              Send Invites
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
