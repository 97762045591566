import { Box, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSongSelector } from '../../store/selectors/song.selector';

export type SongKeyProps = {
  canEdit: boolean;
};

export function SongKey({ canEdit }: SongKeyProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [inputKey, setInputKey] = useState('A');
  const [oldKey, setOldKey] = useState('A');
  const { editKey, key } = useSongSelector();

  useEffect(() => {
    if (key) {
      setInputKey(key);
      setOldKey(key);
    } else {
      setInputKey('A');
      setOldKey('A');
    }
  }, [key]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '12px'
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '17px',
          color: '#ffff'
        }}
      >
        Key
      </Typography>
      {canEdit ? (
        <TextField
          size="small"
          value={inputKey}
          disabled={!canEdit}
          sx={{
            width: '45px',
            maxHeight: '27px',
            fontSize: '14px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px'
            },
            '& fieldset': { border: 'none', borderRadius: '4px' },
            '& .MuiInputLabel-root': { fontSize: '14px' },
            '& .MuiInputBase-input': { fontSize: '14px', padding: '4px 10px', fontWeight: '400', lineHeight: '17px' },
            '& .MuiInputBase-root': { backgroundColor: '#2B2B2B', maxHeight: '27px' }
          }}
          onChange={e => {
            setInputKey(e.target.value);
          }}
          onBlur={e => {
            if (oldKey !== inputKey) {
              setOldKey(inputKey);
              editKey(e.target.value);
              enqueueSnackbar('Key changed successfully', { variant: 'success' });
            }
          }}
          type="text"
        />
      ) : null}
    </Box>
  );
}
