import { create } from 'zustand';
import { SharedUserInfoUploadPage } from '../@types/sharing';
import { CreateParentSongResponse, stemToUpload, uploadFile, UploadFileRequest } from '../@types/uploadFile';
import songsService from '../services/songsService';
import trackEvent from '../services/trackService';
import uploadService from '../services/uploadService';
import { TaskQueue } from '../utils/TaskQueue';
type songUploadState = {
  song: uploadFile | null;
  stems: stemToUpload[];
  folderId: string;
  songId: string;
  totalEstimatedTime: number;
  totalProgress: number;
  isNewSong: boolean;
  uploadErrors: Error | string | null;
  isUploadingSong: boolean;
};

type UploadFilesState = {
  currentPreparedSharedUsers: SharedUserInfoUploadPage[];
  uploadingSongs: songUploadState[];
  lastQueue: number;
};

const uploadQueue = new TaskQueue(10);
const zipQueue = new TaskQueue(5);

type UploadFilesActions = {
  setUploadData: (song: uploadFile | null, stems: stemToUpload[], songId: string, folderId: string, isNewSong?: boolean) => void;
  addUploadStems: (stems: stemToUpload[], songId: string, folderId: string) => void;
  createParentSong: (folderId: string, songId: string, name: string) => Promise<CreateParentSongResponse | null>;
  uploadSong: (
    dataForS3PresignedPost: UploadFileRequest,
    file: File,
    handleProgress: (progress: number, songId: string) => void,
    songId: string,
    handleEstimate?: (estimatedTime: number, songId: string) => void
  ) => Promise<void>;
  uploadStem: (
    dataForS3PresignedPost: UploadFileRequest,
    file: File,
    handleProgress: (progress: number, songId: string) => void,
    songId: string,
    handleEstimate?: (estimatedTime: number, songId: string) => void
  ) => Promise<void>;
  uploadFile: (
    dataForS3PresignedPost: UploadFileRequest,
    file: File,
    handleProgress: (progress: number, songId: string) => void,
    songId: string,
    handleEstimate?: (estimatedTime: number, songId: string) => void
  ) => Promise<void>;
  handleProgressForSong: (progress: number, songId: string) => void;
  handleProgressForStems: (index: number, progress: number, songId: string) => void;
  handleEstimatedTimeForSong: (estimatedTime: number, songId: string) => void;
  handleEstimatedTimeForStems: (index: number, estimatedTime: number, songId: string) => void;
  handleTotalEstimatedTime: (songId: string) => void;
  handleTotalProgress: (songId: string) => void;
  removeUploadSong: (songId: string) => void;
  addPreparedSharedUser: (email: string, accessType: string) => void;
  deletePreparedSharedUser: (email: string) => void;
};

export const useUploadFilesStore = create<UploadFilesState & UploadFilesActions>((set, get) => ({
  lastQueue: 0,
  uploadingSongs: [],
  currentPreparedSharedUsers: [],
  addUploadStems: (stems, songId, folderId) => {
    if (get().uploadingSongs.some(uploadSong => uploadSong.songId === songId)) {
      set({
        uploadingSongs: get().uploadingSongs.map(uploadingSong => {
          if (uploadingSong.songId === songId) {
            return { ...uploadingSong, stems: [...uploadingSong.stems, ...stems] };
          } else return uploadingSong;
        }),
        lastQueue: get().lastQueue + 1
      });
    } else {
      get().setUploadData(null, stems, songId, folderId);
    }
  },
  setUploadData: (song, stems, songId, folderId, isNewSong?: boolean) => {
    set({
      uploadingSongs: [
        ...get().uploadingSongs,
        {
          song,
          stems: stems.map(stem => ({ ...stem, queueId: get().lastQueue + 1 })),
          isNewSong: !!isNewSong,
          totalEstimatedTime: 0,
          totalProgress: 0,
          songId,
          folderId,
          uploadErrors: null,
          isUploadingSong: false
        }
      ],
      lastQueue: get().lastQueue + 1,
      currentPreparedSharedUsers: []
    });
  },
  createParentSong: async (folderId, songId, name) => {
    try {
      const fileData = await songsService.createParentSong(folderId, songId, name);

      return fileData;
    } catch (e: any) {
      set({
        uploadingSongs: get().uploadingSongs.map(uploadingSong => {
          if (uploadingSong.songId === songId) {
            return { ...uploadingSong, uploadErrors: e.message };
          } else return uploadingSong;
        })
      });

      return null;
    }
  },
  uploadFile: async (dataForS3PresignedPost, file, handleProgress, songId, handleEstimate) => {
    try {
        
        await songsService.createFile(dataForS3PresignedPost);
      
        setTimeout(async() => {
          const zipFile: File = await zipQueue.runTask(async () => await uploadService.gzipFile(file));
          dataForS3PresignedPost.meta.gzippedSize = zipFile.size;
          trackEvent('upload', {
            name: dataForS3PresignedPost?.meta.name,
            type: dataForS3PresignedPost?.meta.mimeType,
            originalSize: dataForS3PresignedPost?.meta.originalSize,
            gzippedSize: dataForS3PresignedPost?.meta.gzippedSize,
            songId,
          });
          const s3PresignedPost =await songsService.createPresignedPost(dataForS3PresignedPost);
          uploadQueue.runTask(
          async () => await uploadService.submitFileToS3(zipFile, s3PresignedPost, songId, handleProgress, handleEstimate)
        );
        }, 0);
    } catch (e: any) {
      set({
        uploadingSongs: get().uploadingSongs.map(uploadingSong => {
          if (uploadingSong.songId === songId) {
            return { ...uploadingSong, uploadErrors: e.message };
          } else return uploadingSong;
        })
      });
    }
  },
  uploadSong: async (dataForS3PresignedPost, file, handleProgress, songId, handleEstimate) => {
    set({
      uploadingSongs: get().uploadingSongs.map(uploadingSong => {
        if (uploadingSong.songId === songId) {
          return { ...uploadingSong, isUploadingSong: true };
        } else return uploadingSong;
      })
    });
    return await get().uploadFile(dataForS3PresignedPost, file, handleProgress, songId, handleEstimate);
  },
  uploadStem: async (dataForS3PresignedPost, file, handleProgress, songId, handleEstimate) => {
    return await get().uploadFile(dataForS3PresignedPost, file, handleProgress, songId, handleEstimate);
  },
  handleProgressForSong: (progress: number, songId: string) => {
    set({
      uploadingSongs: get().uploadingSongs.map((uploadingSong: songUploadState) => {
        if (uploadingSong.songId === songId) {
          return { ...uploadingSong, song: ({ ...uploadingSong.song, progress } as uploadFile) ?? null };
        } else return uploadingSong;
      })
    });

    get().handleTotalProgress(songId);
  },
  handleProgressForStems: (index: number, progress: number, songId: string) => {
    set({
      uploadingSongs: get().uploadingSongs.map((uploadingSong: songUploadState) => {
        if (uploadingSong.songId === songId) {
          return { ...uploadingSong, stems: uploadingSong.stems.map((stem, i) => (i === index ? { ...stem, progress } : stem)) };
        } else return uploadingSong;
      })
    });
    get().handleTotalProgress(songId);
  },
  handleEstimatedTimeForSong: (estimatedTime: number, songId: string) => {
    set({
      uploadingSongs: get().uploadingSongs.map((uploadingSong: songUploadState) => {
        if (uploadingSong.songId === songId) {
          return { ...uploadingSong, song: ({ ...uploadingSong.song, estimatedTime } as uploadFile) ?? null };
        } else return uploadingSong;
      })
    });

    get().handleTotalEstimatedTime(songId);
  },
  handleEstimatedTimeForStems: (index: number, estimatedTime: number, songId: string) => {
    set({
      uploadingSongs: get().uploadingSongs.map((uploadingSong: songUploadState) => {
        if (uploadingSong.songId === songId) {
          return { ...uploadingSong, stems: uploadingSong.stems.map((stem, i) => (i === index ? { ...stem, estimatedTime } : stem)) };
        } else return uploadingSong;
      })
    });
    get().handleTotalEstimatedTime(songId);
  },
  handleTotalEstimatedTime: (songId: string) => {
    const currentEstimatedTimeSong = Number(
      get().uploadingSongs.find(uploadSong => uploadSong.songId === songId)?.song?.estimatedTime ?? 0
    );
    const currentEstimatedTimeStems = Number(
      get()
        .uploadingSongs.find(uploadSong => uploadSong.songId === songId)
        ?.stems?.reduce((total, stem) => total + stem.estimatedTime, 0)
    );

    const totalEstimatedTime = currentEstimatedTimeSong + currentEstimatedTimeStems;
    set({
      uploadingSongs: get().uploadingSongs.map((uploadingSong: songUploadState) => {
        if (uploadingSong.songId === songId) {
          return { ...uploadingSong, totalEstimatedTime };
        } else return uploadingSong;
      })
    });
  },
  removeUploadSong: (songId: string) => {
    set({
      uploadingSongs: get().uploadingSongs.filter((uploadingSong: songUploadState) => {
        return uploadingSong.songId !== songId;
      })
    });
  },
  handleTotalProgress: (songId: string) => {
    const currentProgressStems = Number(
      get()
        .uploadingSongs.find(uploadSong => uploadSong.songId === songId)
        ?.stems?.reduce((total, stem) => total + stem.progress, 0)
    );
    const currentProgressSongs = Number(get().uploadingSongs.find(uploadSong => uploadSong.songId === songId)?.song?.progress ?? 0);
    const currentProgress = currentProgressStems + currentProgressSongs;

    const filesCount =
      (get().uploadingSongs.find(uploadSong => uploadSong.songId === songId)?.stems?.length ?? 0) +
      (get().uploadingSongs.find(uploadSong => uploadSong.songId === songId)?.song ? 1 : 0);

    const totalProgress = currentProgress / filesCount;

    set({
      uploadingSongs: get().uploadingSongs.map((uploadingSong: songUploadState) => {
        if (uploadingSong.songId === songId) {
          return { ...uploadingSong, totalProgress };
        } else return uploadingSong;
      })
    });
  },
  addPreparedSharedUser: (email, accessType) => {
    set({ currentPreparedSharedUsers: [...get().currentPreparedSharedUsers, { email, accessType }] });
  },
  deletePreparedSharedUser: email => {
    set({ currentPreparedSharedUsers: get().currentPreparedSharedUsers.filter(u => u.email !== email) });
  }
}));
