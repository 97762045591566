import { Grid } from '@mui/material';
import { ReactNode } from 'react';

type MetadataBoxWrapperProps = {
  children: ReactNode;
};

export function MetadataBoxWrapper({ children }: MetadataBoxWrapperProps) {
  return (
    <Grid item p={1.25} sx={{ backgroundColor: '#2C2C2C66', borderRadius: 1 }}>
      {children}
    </Grid>
  );
}
