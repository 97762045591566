import { OrganizationRoles, OrganizationUser } from '../@types/organizations';
import axios from '../utils/axios';

const getUsers = async (organizationId: string): Promise<OrganizationUser[]> => {
  return await axios.get<OrganizationUser[]>(`organizations/${organizationId}`).then(res => res.data);
};

const deleteMember = async (organizationId: string, memberId: string): Promise<void> => {
  await axios.delete(`organizations/${organizationId}/members/${memberId}`);
};

const deleteInvitation = async (organizationId: string, invitationId: string): Promise<void> => {
  await axios.delete(`organizations/${organizationId}/invitations/${invitationId}`);
};

const inviteMember = async (organizationId: string, sender: string, email: string, role: string): Promise<string> => {
  return await axios.post<string>(`organizations/${organizationId}/invite-member`, { sender, email, role }).then(res => res.data);
};

const switchRoleMember = async (orgId: string, mid: string, oldRole: OrganizationRoles, newRole: OrganizationRoles): Promise<void> => {
  await axios.post(`organizations/${orgId}/switch-role-member/${mid}`, { oldRole, newRole });
};

const switchRoleInvitedUser = async (
  orgId: string,
  iid: string,
  sender: string,
  email: string,
  newRole: OrganizationRoles
): Promise<string> => {
  return await axios.post<string>(`organizations/${orgId}/switch-role-invited-user`, { sender, email, iid, newRole }).then(res => res.data);
};

const organizationsService = { getUsers, deleteMember, deleteInvitation, inviteMember, switchRoleMember, switchRoleInvitedUser };

export default organizationsService;
