import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Stack, TextField, Typography } from '@mui/material';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { NotificationType } from '../@types/notifications';
import { AudioFile } from '../@types/songs';
import notificationService from '../services/notificationService';
import songMetadataService from '../services/songMetadataService';
import trackEvent from '../services/trackService';
import { usePlaylistStore } from '../store/players.store';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { useUserStore } from '../store/user.store';
import { formatDateMDY } from '../utils/date';
import { calculateFolderSize, FolderNode, getFile, readDirectory } from '../utils/fileUtils';
import { generateId } from '../utils/generateId';
import { useBeforeUnload } from '../utils/useBeforeUnload';
import { processUnZipFile } from '../utils/useUnzipWorker';
import { DialogCloseButton } from './DialogCloseButton';
import { DialogUploadSongArea } from './DialogUploadSongArea';
import { useAuth0 } from '@auth0/auth0-react';

type UploadNewSongVersionDialogProps = {
  open: boolean;
  onClose: () => void;
  folderId: string;
  songId: string;
  rerender: (stems: AudioFile[]) => void;
};

export default function UploadNewSongVersionDialog({ open, onClose, folderId, songId, rerender }: UploadNewSongVersionDialogProps) {
  useBeforeUnload();
  const [song, setSong] = useState<File | null>(null);
  const [stems, setStems] = useState<File[]>([]);
  const { addVersion, changeVersion, isUploading, setCachedFiles, renameSong } = useSongSelector();
  const { setUploadData, lastQueue } = useUploadFilesSelector();
  const { user } = useAuth0();

  const init = usePlaylistStore(state => state.init);
  const handleIsStemsRendered = usePlaylistStore(state => state.handleIsStemsRendered);
  const setStemsCountForLoading = usePlaylistStore(state => state.setStemsCountForLoading);
  const [description, setDescription] = useState<string>('');
  const { renameSongInSidebar } = useFoldersSelector();
  const { enqueueSnackbar } = useSnackbar();
  const maxSizeStorage = useUserStore(state => state.maxSizeStorage);
  const usedStorage = useUserStore(state => state.usedStorage);
  const navigate = useNavigate();

  const handleCreateNewVersion = async () => {
    const size = ((song?.size ?? 0) + stems.reduce((acc, stem) => acc + stem.size, 0)) / 1024 ** 3;

    if (!(size + usedStorage > maxSizeStorage)) {
      const songName = song?.name ?? stems?.at(0)?.name ?? 'DEMO_' + formatDateMDY(new Date());

      const newVersion = await addVersion(songId, songName, description);

      if (newVersion?.songName) {
        newVersion.songName = songName;
      }

      init(newVersion?.id!);
      enqueueSnackbar('New version created successfully', { variant: 'success' });

      const metadata = await songMetadataService.getFullMetadata(newVersion?.id!);

      await changeVersion(newVersion?.id!);

      const stemsIds = stems.map(
        _ =>
          'stem_' +
          generateId() +
          '_' +
          new Date()
            .toISOString()
            .replace(/[-T:.]/g, '_')
            .slice(0, -1)
      );

      const storeStems: AudioFile[] = stems.map((stem, i) => ({
        id: stemsIds[i],
        mime: stem.type,
        name: stem.name,
        size: stem.size,
        order: i + 1,
        url: URL.createObjectURL(stem)
      }));

      renameSongInSidebar(folderId!, songId!, songName, user?.name || user?.email);
      setStemsCountForLoading(storeStems.length, newVersion?.id!);
      setCachedFiles(
        song
          ? {
              id:
                'song_' +
                generateId() +
                '_' +
                new Date()
                  .toISOString()
                  .replace(/[-T:.]/g, '_')
                  .slice(0, -1),
              mime: song.type,
              name: song.name,
              size: song.size,
              order: 0,
              url: URL.createObjectURL(song)
            }
          : null,
        storeStems,
        metadata,
        songId,
        description ?? '',
        0
      );

      rerender(storeStems);

      setUploadData(
        song ? { file: song, progress: 0, estimatedTime: 0, versionId: newVersion?.id! } : null,
        stems.map((stem, i) => ({
          file: stem,
          progress: 0,
          estimatedTime: 0,
          stemId: stemsIds[i],
          queueId: lastQueue + 1,
          versionId: newVersion?.id!,
          order: i + 1
        })),
        songId,
        folderId
      );

      notificationService.createNotification(NotificationType.UploadNewVersion, songId);
      trackEvent('upload_new_version', { songId });
      onClose();
    } else {
      enqueueSnackbar("You haven't enough space. Update your plan.", { variant: 'error' });

      navigate('/plans');
    }
  };

  const isZipFile = (file: File): boolean => {
    return file.type === 'application/zip' || file.name.endsWith('.zip');
  };

  const handleUploadSong = (files: FileList) => {
    setSong(files[0] ?? null);
  };

  const checkEntries = async (entries: FileSystemEntry[]): Promise<boolean> => {
    for (const entry of entries) {
      if (entry.isDirectory) {
        return false;
      }

      const file = await getFile(entry as FileSystemFileEntry);
      const mimeType = file.type || 'application/octet-stream';

      if (!mimeType.startsWith('audio/')) {
        return false;
      }
    }

    return true;
  };

  function sortFolderNode(folderNode: FolderNode): FolderNode {
    folderNode.files.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    folderNode.subFolders.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    folderNode.subFolders.forEach(sortFolderNode);

    return folderNode;
  }

  const handleUploadStems = async (fileList?: FileList, fileSystemEntry?: FileSystemEntry[]) => {
    const folderTree: FolderNode[] = [];
    let newStems: File[] = [];
    if (fileSystemEntry?.length) {
      const justAudioFiles = await checkEntries(fileSystemEntry);

      const filePromises = fileSystemEntry.map(async entry => {
        if (entry.isDirectory) {
          const folderNode = await readDirectory(entry as FileSystemDirectoryEntry);

          folderTree.push(folderNode);
        } else {
          const file = await getFile(entry as FileSystemFileEntry);
          const mimeType = file.type || 'application/octet-stream';

          if (mimeType === 'application/zip') {
            const stems = await processUnZipFile(file);

            folderTree.push(...stems);
          } else if (justAudioFiles) {
            if (!folderTree.length) {
              folderTree.push({ id: 'no_folder', name: 'no folder', files: [], subFolders: [] });
            }

            folderTree?.at(0)?.files.push(file);
          }
        }
      });

      await Promise.all(filePromises);
    } else if (fileList?.length) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.type === 'application/zip') {
          const stems = await processUnZipFile(file);
          folderTree.push(...stems);
        } else {
          if (!folderTree.length) {
            folderTree.push({ id: 'no_folder', name: 'no folder', files: [], subFolders: [] });
          }

          folderTree?.at(0)?.files.push(file);
        }
      }
    }

    const sortedFolderTree = folderTree.map(folder => sortFolderNode(folder));

    newStems = sortedFolderTree?.at(0)?.files ?? [];

    setStems(prevStems => [...prevStems, ...newStems]);
  };

  const handleDeleteSong = () => {
    setSong(null);
  };

  const handleDeleteStem = (stemIndex: number) => {
    setStems(prevStems => prevStems.filter((stem, i) => stemIndex !== i));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>Upload new version</DialogTitle>
      <DialogContent dividers sx={{ position: 'relative' }}>
        <Stack spacing={1} height={1}>
          <TextField
            label="Song version description here (optional)"
            multiline
            rows={5}
            fullWidth
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {song ? (
            <Stack flexDirection="column" spacing={1.25}>
              <Typography>Song</Typography>
              <Stack
                sx={{ background: 'rgba(44, 44, 44, 0.40)' }}
                p={1.25}
                borderRadius={0.5}
                flexDirection="row"
                justifyContent="space-between"
              >
                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                  <Icon>
                    <img src={'/assets/song.svg'} height={24} width={24} alt="song" />
                  </Icon>
                  <Typography fontWeight={300} fontSize={14}>
                    {song.name}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                  <Button
                    disabled={isUploading}
                    onClick={handleDeleteSong}
                    variant="contained"
                    size="small"
                    color="secondary"
                    sx={{ height: '28px', width: '28px', minWidth: 'unset' }}
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <DialogUploadSongArea
              disabled={isUploading}
              height="140px"
              title={
                <Typography textAlign="center" variant="body2">
                  Drag and drop or upload the <br /> associated Song here
                </Typography>
              }
              onUpload={handleUploadSong}
            />
          )}
          {!!stems.length ? (
            <Stack flexDirection="column" spacing={1.25}>
              <Typography>Stems</Typography>
              <SimpleBar style={{ maxHeight: '200px' }}>
                <Grid container flexDirection="column" rowGap={0.5}>
                  {stems.map((stem, i) => (
                    <Grid item key={stem.name}>
                      <Stack
                        sx={{ background: 'rgba(44, 44, 44, 0.40)' }}
                        p={1.25}
                        borderRadius={0.5}
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Stack flexDirection="row" alignItems="center" columnGap={1}>
                          <Icon>
                            <img src={'/assets/wave.svg'} height={24} width={24} alt="song" />
                          </Icon>
                          <Typography fontWeight={300} fontSize={14}>
                            {stem.name}
                          </Typography>
                        </Stack>
                        <Stack flexDirection="row" alignItems="center" columnGap={1}>
                          <Button
                            disabled={isUploading}
                            onClick={() => handleDeleteStem(i)}
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{ height: '28px', width: '28px', minWidth: 'unset' }}
                          >
                            <DeleteIcon fontSize="small" />
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </SimpleBar>
            </Stack>
          ) : (
            <DialogUploadSongArea
              disabled={isUploading}
              height="140px"
              multiple={true}
              title={
                <Typography textAlign="center" variant="body2">
                  Drag and drop or upload the <br /> associated Stems here
                </Typography>
              }
              onUpload={handleUploadStems}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isUploading} onClick={() => handleCreateNewVersion()} size="small" variant="contained" color="info">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
