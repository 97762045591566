import { Box, Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import { useAuth0 } from '@auth0/auth0-react';

export function VerificationEmailPage() {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const { user } = useAuth0();
  const navigate = useNavigate();
  let interval: NodeJS.Timeout;

  useEffect(() => {
    interval = setInterval(() => {
      authService.checkEmailVerification().then(emailVerified => {
        setIsEmailVerified(emailVerified);
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isEmailVerified) {
      clearInterval(interval);

      navigate('/dashboard');
    }
  }, [isEmailVerified]);

  return (
    <Stack sx={{ background: '#000000' }} height="100vh" width="100%" position="absolute" alignItems={'center'} justifyContent="center">
      <Stack height="410px" width="620px" sx={{ background: '#151515' }} borderRadius={2} position="relative">
        <Box position={'absolute'} left={'50%'} top={'-45px'} sx={{ transform: 'translateX(-50%)' }}>
          <img src={'/assets/verificationEmailIcon.svg'} height={90} width={90} alt="verification email icon" />
        </Box>
        <Stack py={7} alignItems={'center'}>
          <Typography fontSize={20} lineHeight={'24px'} fontWeight={500} textAlign={'center'} mb={1.5}>
            Please verify your email
          </Typography>
          <Typography fontSize={16} lineHeight={'20px'} fontWeight={400} textAlign={'center'} mb={5}>
            You’re almost there! We sent an email to <br /> {user?.email}
          </Typography>
          <Typography fontSize={16} lineHeight={'20px'} fontWeight={400} textAlign={'center'} mb={0.5}>
            Just click on the link in that email to complete your sign up.
          </Typography>
          <Typography fontSize={16} lineHeight={'20px'} fontWeight={400} textAlign={'center'} mb={3.5}>
            If you don’t see it, you may need to <b>check your spam folder.</b>
          </Typography>
          <Typography fontSize={16} lineHeight={'20px'} fontWeight={400} textAlign={'center'} mb={3.5}>
            Still can’t find the email? No problem.
          </Typography>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="info"
            sx={{ height: '34px', width: '132px', fontSize: '16px', p: 0 }}
            onClick={async () => {
              await authService.resendEmailVerification();
            }}
          >
            Resend Email
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
