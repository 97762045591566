import { Auth0Provider } from '@auth0/auth0-react';
import React, { ReactNode } from 'react';
import { auth0Config } from '../configs';

type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  return <Auth0Provider {...auth0Config}>{children}</Auth0Provider>;
}
