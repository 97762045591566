import Grid from '@mui/material/Grid';
import { SidebarFolder } from '../../../@types/folders';
import FolderDropDownItem from './FolderDropDownItem';

export default function FolderDropDownItemsList({
  folder,
  highlightedSongId,
  setHighlightedSongId,
  isSharingFiles = false,
  isPublicLocation = false
}: {
  folder: SidebarFolder;
  highlightedSongId: string;
  setHighlightedSongId: (songId: string) => void;
  isSharingFiles?: boolean;
  isPublicLocation?: boolean;
}) {
  return (
    <Grid container direction="column" spacing={1}>
      {folder.songs.map(song => (
        <Grid item key={song.id} maxWidth={'100% !important'}>
          <FolderDropDownItem
            highlightedSongId={highlightedSongId}
            setHighlightedSongId={setHighlightedSongId}
            song={song}
            isSharingFile={isSharingFiles}
            isPublicLocation={isPublicLocation}
            isDefault={folder.name === 'default_folder_for_uploads' || folder.name === 'example'}
          />
        </Grid>
      ))}
    </Grid>
  );
}
