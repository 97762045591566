import SubNavbar from '../components/SubNavbar';
import SimpleBar from 'simplebar-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
export default function Billings() {
  return (
    <>
      <SimpleBar style={{ maxHeight: `calc(100vh - 70px)` }}>
        <SubNavbar />
        <div style={{ width: '70%', margin: '0 auto', marginTop: '30px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Billing method
          </Typography>
          <Box
            component="section"
            sx={{
              p: 2,
              border: '1px solid grey',
              borderRadius: '10px',
              height: '108px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#8B8B8B0D'
            }}
          >
            <button
              style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: 'white', color: 'black', height: 'auto', width: 'auto' }}
            >
              {' '}
              Add Payment
            </button>
          </Box>
          <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '20px' }}>
            Invoices
          </Typography>
          <Box
            component="section"
            sx={{
              p: 2,
              border: '1px solid grey',
              height: '290px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#8B8B8B0D'
            }}
          >
            <span style={{ fontFamily: 'DM Sans', fontSize: '16px' }}>No invoices yet...</span>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}></div>
        </div>
      </SimpleBar>
    </>
  );
}
