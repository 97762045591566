export type OrganizationUser = {
  id: string;
  userName: string;
  invitationId: string;
  email: string;
  role: string;
  status: string;
};

export enum OrganizationRoles {
  Owner = 'Owner',
  Admin = 'Admin',
  Member = 'Member'
}
