import React from 'react';
import { TableContainer, Paper, Table, TableRow, TableCell, TableBody,  } from '@mui/material';
import { Button } from '@mui/material';
import { string } from 'yup';

type ProfileTableProps = {
  data: { name: string; value: string | React.ReactNode; icon?: React.ReactNode }[];
};

export function ProfileTable({ data }: ProfileTableProps) {
  return (
    <div style={{ marginTop: '10px', marginBottom: '50px' }}>
      <TableContainer component={Paper} sx={{ borderRadius: '10px', border: '1px solid grey' }}>
        <Table sx={{ borderCollapse: 'collapse' }}>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  height: '70px',
                  backgroundColor: 'black',
                  borderBottom: '1px solid grey'
                }}
              >
                <TableCell sx={{ color: 'grey', borderColor: 'grey' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {row.icon && <div style={{ marginRight: '10px' }}>{row.icon}</div>}
                    {row.name}
                  </div>
                </TableCell>
                <TableCell sx={{ color: 'white', borderColor: 'grey' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    {typeof row.value === 'string' ? (
                      <span>{row.value}</span>
                    ) : (
                      <Button variant="text">
                          {row.value}
                      </Button>
                    )}
                    
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
