import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '', { api_host: "https://mixpanel.demo.studio",});
const trackEvent = async (
    name: string, props?: any
  ): Promise<void> => {
    if (!mixpanel) {
      return;
    }
    mixpanel.track(name, props||{});
  };

  export default trackEvent;