import { create } from 'zustand';
import { OrganizationRoles, OrganizationUser } from '../@types/organizations';
import organizationsService from '../services/organizationsService';

type OrganizationState = {
  organizationUsers: OrganizationUser[];
};

type OrganizationActions = {
  getOrganizationUsers: (orgId: string) => Promise<void>;
  deleteMember: (orgId: string, mid: string) => Promise<void>;
  deleteInvitation: (orgId: string, iid: string) => Promise<void>;
  switchRoleMember: (orgId: string, mid: string, oldRole: OrganizationRoles, newRole: OrganizationRoles) => Promise<void>;
  switchRoleInvitedUser: (orgId: string, iid: string, sender: string, email: string, newRole: OrganizationRoles) => Promise<void>;
  inviteUser: (orgId: string, sender: string, email: string, role: string) => Promise<void>;
};

export const useOrganizationsStore = create<OrganizationState & OrganizationActions>((set, get) => ({
  organizationUsers: [],
  getOrganizationUsers: async orgId => {
    const users = await organizationsService.getUsers(orgId);

    set({ organizationUsers: users });
  },
  deleteMember: async (orgId, mid) => {
    await organizationsService.deleteMember(orgId, mid);

    set({ organizationUsers: get().organizationUsers.filter(m => m.id !== mid) });
  },
  deleteInvitation: async (orgId, iid) => {
    await organizationsService.deleteInvitation(orgId, iid);

    set({ organizationUsers: get().organizationUsers.filter(m => m.invitationId !== iid) });
  },
  switchRoleInvitedUser: async (orgId, iid, sender, email, newRole) => {
    const newIid = await organizationsService.switchRoleInvitedUser(orgId, iid, sender, email, newRole);

    set({
      organizationUsers: get().organizationUsers.map(u => (u.invitationId === iid ? { ...u, invitationId: newIid, role: newRole } : u))
    });
  },
  switchRoleMember: async (orgId, mid, oldRole, newRole) => {
    await organizationsService.switchRoleMember(orgId, mid, oldRole, newRole);

    set({ organizationUsers: get().organizationUsers.map(u => (u.id === mid ? { ...u, role: newRole } : u)) });
  },
  inviteUser: async (orgId, sender, email, role) => {
    const iid = await organizationsService.inviteMember(orgId, sender, email, role);

    set({ organizationUsers: [...get().organizationUsers, { email, status: 'Pending', invitationId: iid, role, userName: '', id: '' }] });
  }
}));
