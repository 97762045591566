import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Folder } from '../../../@types/folders';
import { DialogCloseButton } from '../../../components/DialogCloseButton';

type DeleteFolderDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => any;
  folder: Folder;
};

export default function DeleteFolderDialog({ open, onClose, onConfirm, folder }: DeleteFolderDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>Delete folder "{folder.name}"</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          If you delete this folder, all songs in the folder and their associated stems will be deleted. This can’t be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} size="small" color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={onConfirm} size="small" color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
