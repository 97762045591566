import { Typography } from '@mui/material';
import React from 'react';
import { formatDateWDM } from '../../utils/date';

export type SongDateUploadingProps = {
  uploadedBy: string;
  createdAt: string;
};

export function SongDateUploading({ uploadedBy, createdAt }: SongDateUploadingProps) {
  return (
    <Typography fontSize={12} color="text.secondary">
      {`${uploadedBy} uploaded this version ${formatDateWDM(createdAt ?? '')}`}
    </Typography>
  );
}
