import { Button, Grid, Typography } from '@mui/material';
import paymentService from '../../services/paymentService';

type CancelSubscriptionProps = {
  onClose: () => void;
  refetchPlan: () => Promise<void>;
};

export default function CancelSubscription({ onClose, refetchPlan }: CancelSubscriptionProps) {
  return (
    <Grid sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)' }}>
      <Grid item sx={{ gridColumn: '3 / 12' }}>
        <Typography fontSize={28} sx={{ lineHeight: '35px', paddingBottom: '20px' }}>
          Сancel subscription
        </Typography>
      </Grid>

      <Grid item sx={{ gridColumn: '3 / 12' }}>
        <Typography fontSize={18} sx={{ lineHeight: '40px' }}>
          When your billing cycle ends on 1/1/2024, you’ll loose access to any files exceeding the free 2GB limit.
        </Typography>
      </Grid>

      <Grid item sx={{ gridColumn: '3 / 12' }}>
        <Typography fontSize={18} sx={{ lineHeight: '40px', paddingBottom: '20px' }}>
          Be sure to download all files before then to ensure you don’t loose anything!
        </Typography>
      </Grid>

      <Grid item sx={{ gridColumn: '3 / 12' }}>
        <Button
          variant="contained"
          sx={{
            padding: '5px 8px',
            fontWeight: 400,
            marginRight: '21px',
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '-0.4%',
            backgroundColor: '#F30000'
          }}
          onClick={async () => {
            await paymentService.cancelSubRenewal().then(() => refetchPlan());
            onClose();
          }}
        >
          Cancel subscription
        </Button>

        <Button
          variant="contained"
          sx={{
            padding: '5px 8px',
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '-0.4%',
            backgroundColor: '#008EF3'
          }}
          onClick={onClose}
        >
          Wait, keep my account
        </Button>
      </Grid>
    </Grid>
  );
}
